import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
import Header from "./components/Header";
import { CalendarIcon } from "@chakra-ui/icons";
import DatePicker from "react-datepicker";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import ReactQuill from "react-quill";

import { onSave, formatDate } from "../../scripts/api";
import { getTextLength } from "../../scripts/enteries";
import { trackEvent } from "../../analytics";
import DOMPurify from "dompurify";

const { zonedTimeToUtc, utcToZonedTime, format } = require("date-fns-tz");

const AddEntry = () => {
  let navigate = useNavigate();
  const queryClient = useQueryClient();

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("all");
  const [entryMethod, setEntryMethod] = useState("paste_text");
  const [filteredEntries, setFilteredEntries] = useState([]);

  const SaveEntry = useMutation(onSave, {
    onSuccess: (res: any) => {
      toast.success("Entry Saved!");
      queryClient.invalidateQueries("getEnteriesByRange");
      // queryClient.setQueryData(
      //   ["getEnteries", formatDate(new Date())],
      //   (data: any) => {
      //     queryClient.invalidateQueries("getEnteriesByRange");
      //     return { data: [...data.data, res.data] };
      //   }
      // );
      trackEvent("add_entry");
      navigate("/history");
    },
    onError: (err: any) => {
      toast.error("There was an error.");
    },
  });

  const formik = useFormik({
    initialValues: {
      entryTitle: "",
      text: "",
      wordCount: 0,
      entryDate: new Date(),
    },
    onSubmit: (values: any) => {
      const { entryTitle, text, wordCount, entryDate } = values;
      SaveEntry.mutate({
        entry_count: wordCount,
        entry_note: entryTitle,
        entry_text: DOMPurify.sanitize(text),
        entry_date: entryDate,
      });
    },
  });

  useEffect(() => {
    formik.setFieldValue("text", "");
    formik.setFieldValue("wordCount", 0);
  }, [entryMethod]);

  const onTextChange = (e: any, _: any, __: any, editor: any): void => {
    const count = editor.getText().trim().split(/\s+/).length;
    formik.setFieldValue("text", e);
    formik.setFieldValue("wordCount", count);
  };

  return (
    <Box>
      <Box display={["block", "block", "flex"]}>
        <Box mb={12} mt={12}>
          <Heading as="h2" size="xl" mb={4}>
            Add a New Entry
          </Heading>
          <Text color={"neutral.600"} fontSize={"16px"} mb={4}>
            Add a new entry by{" "}
            <span style={{ color: "#000", fontWeight: "bold" }}>
              pasting the text
            </span>{" "}
            OR by{" "}
            <span style={{ fontWeight: "bold", color: "#000" }}>
              manually entering the word count
            </span>
            .
          </Text>
        </Box>
        <Box mb={12} mt={12} ml={[0, 0, 6]}>
          <Text color={"neutral.600"} fontSize={"16px"} mb={4}>
            * Choose your method of entry
          </Text>

          <Box display={"flex"} mt={2}>
            <Button
              borderRadius="0px"
              className={
                entryMethod === "paste_text"
                  ? "entry-button-active"
                  : "entry-button"
              }
              onClick={() => setEntryMethod("paste_text")}
            >
              Paste Your Text
            </Button>
            <Button
              borderRadius="0px"
              className={
                entryMethod === "input_word_count"
                  ? "entry-button-active"
                  : "entry-button"
              }
              onClick={() => setEntryMethod("input_word_count")}
            >
              Input Word Count
            </Button>
          </Box>
        </Box>
      </Box>
      <Box>
        <Grid
          templateColumns={[
            "repeat(3, 1fr)",
            "repeat(3, 1fr)",
            "repeat(3, 1fr)",
            "repeat(6, 1fr)",
          ]}
          gap={4}
        >
          <GridItem colSpan={15}>
            <Card maxW="5xl" m={[0, 0, 0, "auto"]} w={"100%"} p={"10px"}>
              <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
                <CardBody>
                  <Box w={"100%"} display={["block", "flex"]} gap={4} mb={6}>
                    <FormControl>
                      <FormLabel>Entry Date:</FormLabel>
                      <Box
                        position={"relative"}
                        color={"neutral.500"}
                        border={"1px solid"}
                        borderRadius={"10px"}
                      >
                        <DatePicker
                          selected={formik.values.entryDate}
                          onChange={(date: Date | null) => {
                            formik.setFieldValue("entryDate", date);
                          }}
                        />
                        <Box position={"absolute"} right={"15px"} top={"7px"}>
                          <CalendarIcon />
                        </Box>
                      </Box>
                    </FormControl>
                    {/* Entry Title Field */}
                    <FormControl>
                      <FormLabel
                        size={"sm"}
                        color={"neutral.700"}
                        fontWeight="bold"
                      >
                        Entry Title
                      </FormLabel>
                      <Input
                        type="text"
                        name="entryTitle"
                        id="entryTitle"
                        placeholder="Add Entry Name"
                        border={"1px solid"}
                        value={formik.values.entryTitle}
                        onChange={formik.handleChange}
                      />
                    </FormControl>
                  </Box>
                  {entryMethod === "paste_text" ? (
                    <FormControl>
                      <FormLabel
                        size={"sm"}
                        color={"neutral.700"}
                        fontWeight="bold"
                      >
                        Enter your text
                      </FormLabel>
                      <ReactQuill
                        theme="snow"
                        value={formik.values.text}
                        onChange={onTextChange}
                        // style={{ height: "600px" }}
                      />
                      <Text
                        mt={2}
                        fontWeight={"bold"}
                        color={"brand.300"}
                        fontSize={"16px"}
                      >
                        Word Count: {formik.values.wordCount}
                      </Text>
                    </FormControl>
                  ) : (
                    <FormControl>
                      <FormLabel
                        size={"sm"}
                        color={"neutral.700"}
                        fontWeight="bold"
                      >
                        Enter Word Count
                      </FormLabel>
                      <Input
                        type="number"
                        name="wordCount"
                        id="wordCount"
                        placeholder="0"
                        border={"1px solid"}
                        value={formik.values.wordCount}
                        onChange={formik.handleChange}
                      />
                    </FormControl>
                  )}
                </CardBody>

                {/* Footer */}
                <CardFooter>
                  <Box display={"flex"} w={"100%"} gap={2}>
                    <Button
                      borderRadius={"100px"}
                      p={"16px 24px"}
                      h={"46px"}
                      w={"-webkit-fill-available"}
                      background={"#fff"}
                      color={"accents.300"}
                      _hover={{ bg: "accents.400", color: "#fff" }}
                      border={"1px solid"}
                      variant="solid"
                      onClick={() => navigate("/history")}
                    >
                      Cancel
                    </Button>
                    <Button
                      borderRadius={"100px"}
                      p={"16px 24px"}
                      h={"46px"}
                      w={"100%"}
                      background={"brand.300"}
                      color={"#fff"}
                      _hover={{ bg: "brand.400" }}
                      variant="solid"
                      type="submit"
                    >
                      Save
                    </Button>
                  </Box>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
};

export default AddEntry;
