import { Box, Container } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import { CONTAINER_WIDTH } from "../constants";

const Contact = () => {
  return (
    <Box pt={12}>
      <Container maxW={CONTAINER_WIDTH}>
        <Box my={4} pb={4} maxWidth="560px">
          <Text fontSize={"lg"}>
            Something not working? Have a question? Want to suggest a new
            feature? Use the contact form below to get in touch with us and
            we'll get back to you as soon as possible.
          </Text>
        </Box>
        <iframe
          width="100%"
          height="800"
          src="https://www.beekai.com/view/form/c642f040-162c-4c21-9743-e081efc6bff6"
          title="Feedback"
          frameBorder="0"
          allowFullScreen={true}
        ></iframe>
      </Container>
    </Box>
  );
};

export default Contact;
