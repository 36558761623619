import {
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { useState, useContext } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { login } from "../../scripts/api/user";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import { userAuthContext, userTokenContext } from "../../state";
import { CONTAINER_WIDTH } from "../../constants";

interface LoginValues {
  email: string;
  password: string;
  rememberMe: Boolean;
}

const Login = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const { authError, setAuthError } = useContext(userAuthContext);
  const { setUserToken } = useContext(userTokenContext);
  const [loginError, setLoginError] = useState("");
  const [inRequest, setInRequest] = useState(false);

  const loginHandler = async (
    email: string,
    password: string
  ): Promise<void> => {
    setLoginError("");
    setInRequest(true);

    try {
      const { data } = await login({ email: email, password: password });
      localStorage.setItem("WORDCOUNTER_ACCESS_TOKEN", data.values);
      setUserToken(data.values);
      navigate("../dashboard", { replace: true });
    } catch (e: any) {
      setLoginError("Email or password invalid.");
    }

    setInRequest(false);
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .required("Email is required")
      .email("Please Enter Valid Email"),
    password: Yup.string().required("Password is required").min(6),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values: LoginValues) => {
      loginHandler(values.email, values.password);
    },
  });

  const handleClick = () => {
    setShowPassword((prev: boolean) => !prev);
  };

  return (
    <Container maxW={CONTAINER_WIDTH}>
      <div
        style={{
          backgroundImage: "url('/assets/images/auth_bg.png')",
          width: "100%",
          height: "78vh",
          backgroundSize: "50%",
          backgroundPosition: "100% 50%",
          backgroundRepeat: "no-repeat",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {/* Login Box */}
        <Box
          alignItems={"flex-start"}
          p={"48px 32px"}
          gap={"40px"}
          width={"453px"}
          boxShadow={"1px 3px 15px rgba(0, 0, 0, 0.08)"}
          borderRadius="12px"
          bg={"#fff"}
        >
          {/* Form */}
          <form onSubmit={formik.handleSubmit}>
            {/* Nav */}
            <Flex mb={8}>
              <Link
                to="/login"
                style={{
                  marginRight: "10px",
                }}
              >
                <Text as="b" color="brand.300">
                  Login
                </Text>
              </Link>
              <Center height="25px">
                <Divider orientation="vertical" />
              </Center>
              <Link
                to="/register"
                style={{
                  marginLeft: "10px",
                }}
              >
                <Text as="b">New Account</Text>
              </Link>
            </Flex>

            <Box>
              {loginError.length > 0 && (
                <Text color="accents.300">{loginError}</Text>
              )}
              {authError && authError.length > 0 && (
                <Text color="accents.300">{authError}</Text>
              )}
            </Box>

            {/* Email Field */}
            <FormControl>
              <FormLabel size={"sm"} color={"neutral.700"} fontWeight="bold">
                Email
              </FormLabel>
              <Input
                type="text"
                name="email"
                id="email"
                placeholder="Enter your email"
                border={"1px solid"}
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.email && formik.errors.email ? (
                <Text color="accents.300" fontSize={"xs"}>
                  {formik.errors.email}
                </Text>
              ) : null}
            </FormControl>

            {/* Passord Fild */}
            <FormControl>
              <FormLabel
                mt={2}
                size={"sm"}
                color={"neutral.700"}
                fontWeight="bold"
              >
                Password
              </FormLabel>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  name="password"
                  id="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter a Password"
                  border={"1px solid"}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="md"
                    onClick={handleClick}
                    bg={"transparent"}
                    variant="unstyled"
                  >
                    <Center>
                      {" "}
                      {showPassword ? <BsEyeSlash /> : <BsEye />}
                    </Center>
                  </Button>
                </InputRightElement>
              </InputGroup>
              {formik.touched.password && formik.errors.password ? (
                <Text color="accents.300" fontSize={"xs"}>
                  {formik.errors.password}
                </Text>
              ) : null}
            </FormControl>

            {/* Remember me and forgot password */}
            <Flex justifyContent={"space-between"} mt={2}>
              <Box>
                <Flex>
                  <Checkbox
                    colorScheme="green"
                    iconSize={"10px"}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    id="rememberMe"
                    name="rememberMe"
                  />
                  <Text fontSize="sm" ml={2}>
                    Remember me
                  </Text>
                </Flex>
              </Box>
              {/* <Link to="#">
                <Text fontSize="xs" color="brand.300">
                  Forgot Password?
                </Text>
              </Link> */}
            </Flex>

            {/* Login Button */}
            <Button
              background={"brand.300"}
              color="white"
              _hover={{ bg: "brand.400" }}
              mt={6}
              w={"120px"}
              borderRadius={"100px"}
              type="submit"
              isLoading={inRequest}
            >
              Login
            </Button>
          </form>
        </Box>
      </div>
    </Container>
  );
};

export default Login;
