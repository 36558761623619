import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Header from "./components/Header";
import EditEntry from "./EditEntry";
import { useQuery, useMutation } from "react-query";
import { getEnteriesByRange, onDelete } from "../../scripts/api";
import { useQueryClient } from "react-query";
import { parseEntry } from "../../scripts/enteries";
import EntriesTable from "./components/EntriesTable";
import toast from "react-hot-toast";
import useAuth from "../../hooks/useAuth";
import ViewEntry from "./ViewEntry";

const EmptyEntries = () => {
  return (
    <Box mt={32} mb={24} textAlign={"center"}>
      <Heading as="h2" size="xl" my={2} fontSize={"36px"}>
        You Don’t Have Any Entries
      </Heading>
      <Text
        color={"neutral.600"}
        fontSize={"16px"}
        mb={4}
        textAlign={"center"}
        margin={"auto"}
        width={"40%"}
      >
        Try adding your first entry. Entries will keep you updated on your
        progress of your word count.
      </Text>

      <Link to="/entry">
        <Button
          my={8}
          borderRadius={"100px"}
          p={"16px 24px"}
          h={"46px"}
          w={"160px"}
          leftIcon={<AddIcon />}
          background={"brand.300"}
          color={"#fff"}
          _hover={{ bg: "brand.400" }}
          variant="solid"
        >
          New Entry
        </Button>
      </Link>
    </Box>
  );
};

const searchEntries = (entries: any[], search: string): any => {
  if (search.length < 3) {
    return entries;
  } else {
    let updatedEntries = entries.filter((entry: any) => {
      return entry.entry_note.toLowerCase().includes(search);
    });
    return updatedEntries;
  }
};

const Entries = () => {
  useAuth();
  const [inEdit, setInEdit] = useState<any>(null);
  const [inView, setInView] = useState<any>(null);
  const [confirmDelete, setConfirmDelete] = useState<any>(null);

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("all");

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const onDateChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  // Mutations
  const queryClient = useQueryClient();
  const deleteMutation = useMutation(onDelete, {
    onSuccess: (res: any, id: any) => {
      toast.success("Entry Deleted");
      setConfirmDelete(false);

      queryClient.setQueryData(
        ["getEnteriesByRangeHistory", startDate, endDate],
        (data: any) => {
          const newEntries = data.data.filter((entry: any) => {
            return entry.entry_id !== id;
          });
          return { ...data, data: newEntries };
        }
      );
    },
    onError: (err: any) => {
      toast.error("There was an error.");
    },
  });

  const onEditSuccess = (editedEntry: any): void => {
    queryClient.setQueryData(
      ["getEnteriesByRangeHistory", startDate, endDate],
      (data: any) => {
        const newEntries = data.data.map((entry: any) => {
          return entry.entry_id === editedEntry.entry_id ? editedEntry : entry;
        });

        return { ...data, data: newEntries };
      }
    );
  };

  // Pull our entries
  const entryQuery = useQuery(
    ["getEnteriesByRangeHistory", startDate, endDate],
    () => getEnteriesByRange(startDate, endDate)
  );
  const entries = entryQuery.data?.data || [];
  const filteredEntries = searchEntries(entries, search)?.map(parseEntry) || [];

  return (
    <Box>
      {!inEdit && !inView ? (
        <Header
          text={!inEdit && !inView ? "All Entries" : ""}
          search={search}
          setSearch={setSearch}
          filter={filter}
          setFilter={setFilter}
          startDate={startDate}
          endDate={endDate}
          onDateChange={onDateChange}
        />
      ) : null}
      {inEdit && (
        <EditEntry
          entry={inEdit}
          setInEdit={setInEdit}
          onEditSuccess={onEditSuccess}
        />
      )}
      {filteredEntries.length !== 0 && !inEdit && !inView && (
        <Link to="/entry">
          <Button
            mb={4}
            borderRadius={"100px"}
            h={"46px"}
            w={"160px"}
            leftIcon={<AddIcon />}
            background={"brand.300"}
            color={"#fff"}
            _hover={{ bg: "brand.400" }}
            variant="solid"
          >
            New Entry
          </Button>
        </Link>
      )}
      {filteredEntries.length === 0 && !inEdit && !inView && <EmptyEntries />}
      {filteredEntries.length > 0 && !inEdit && !inView && (
        <EntriesTable
          entries={filteredEntries}
          setInEdit={setInEdit}
          setInView={setInView}
          confirmDelete={confirmDelete}
          setConfirmDelete={setConfirmDelete}
          onDelete={deleteMutation}
        />
      )}
      {inView && <ViewEntry entry={inView} setInView={setInView} />}
    </Box>
  );
};

export default Entries;
