import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

interface SuccessModalProps {
  open: boolean;
  setOpen: Function;
  setInEdit: Function;
}

const CancelModal = ({ open, setOpen, setInEdit }: SuccessModalProps) => {
  return (
    <Modal onClose={() => setOpen(false)} size={"xl"} isOpen={open} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Box textAlign={"center"} my={8}>
            <Text fontSize={"20px"} fontWeight={"bold"} mb={2}>
              This will cancel your editing process!
            </Text>
            <Text fontSize={"16px"} color={"neutral.500"} mb={8}>
              Do you sure you wish to proceed?
            </Text>
            <Flex w={"75%"} gap={2} alignItems={"center"} margin={"auto"}>
              <Button
                borderRadius={"100px"}
                p={"16px 24px"}
                h={"46px"}
                w={"100%"}
                background={"#fff"}
                color={"accents.300"}
                _hover={{ bg: "accents.400", color: "#fff" }}
                border={"1px solid"}
                variant="solid"
                onClick={() => setInEdit(null)}
              >
                Yes, Cancel
              </Button>
              <Button
                borderRadius={"100px"}
                p={"16px 24px"}
                h={"46px"}
                w={"100%"}
                background={"brand.300"}
                color={"#fff"}
                _hover={{ bg: "brand.400" }}
                variant="solid"
                type="submit"
                onClick={() => setOpen(false)}
              >
                No, Keep Editing
              </Button>
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CancelModal;
