import {useEffect, useContext} from "react";
import { useNavigate } from "react-router-dom";
import { userAuthContext, userTokenContext } from '../state';
import { checkAuth } from '../scripts/api/user';

const useAuth = () => {
  const { setAuthError } = useContext(userAuthContext)
  const { setUserToken } = useContext(userTokenContext)
  const navigate = useNavigate();
  
  useEffect(() => {
    // here is where you make API call(s) or any side effects
    checkAuth().then((res: any) => {
      if (res.status !== 200) {
        setAuthError("You must be logged in to view that page.");
        localStorage.setItem('WORDCOUNTER_ACCESS_TOKEN', "");
        setUserToken(null);
        navigate("/login");
      }
    }).catch((err: any) => {
      setAuthError("You must be logged in to view that page.");
      localStorage.setItem('WORDCOUNTER_ACCESS_TOKEN', "");
      setUserToken(null);
      navigate("/login");
    })
  }, [] )
}

export default useAuth;
