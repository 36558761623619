import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { ArrowBackIcon, CalendarIcon, CloseIcon } from "@chakra-ui/icons";
import DatePicker from "react-datepicker";
import { useFormik } from "formik";
import { FiEdit2 } from "react-icons/fi";
import { useMutation } from "react-query";
import { onEdit, formatDateAfterEdit } from "../../scripts/api";
import { useQueryClient } from "react-query";
import SuccessModal from "../../components/SuccessModal";
import ErrorMessage from "../../components/ErrorMessage";
import CancelModal from "../../components/CancelModal";
import format from "date-fns/format";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";

interface EntryProps {
  entry: any;
  setInEdit: Function;
  onEditSuccess: Function;
}

const EditEntry = ({ entry, setInEdit, onEditSuccess }: EntryProps) => {
  const startVal =
    entry.entry_text?.length > 0 ? "paste_text" : "input_word_count";
  const queryClient = useQueryClient();
  const [successModal, setSuccessModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [error, setError] = useState(false);
  const [entryMethod, setEntryMethod] = useState(startVal);
  const [wordCount, setWordCount] = useState(0);

  const EditMutation = useMutation(onEdit, {
    onSuccess: (res: any, editedEntry: any) => {
      onEditSuccess({
        ...editedEntry,
        entry_date: formatDateAfterEdit(editedEntry.entry_date),
      });
      setCancelModal(false);
      setSuccessModal(true);

      setTimeout(function () {
        setSuccessModal(false);
        setInEdit(null);
      }, 1000);
    },
    onError: (err: any) => {
      setError(true);
    },
  });

  const formik = useFormik({
    initialValues: {
      entryTitle: entry.entry_note,
      text: entry.entry_text,
      wordCount: entry.entry_count,
      entryDate: new Date(entry.entry_date),
    },
    onSubmit: (values: any) => {
      EditMutation.mutate({
        entry_id: entry.entry_id,
        entry_note: values.entryTitle,
        entry_count: values.wordCount,
        entry_text: DOMPurify.sanitize(values.text),
        entry_date: values.entryDate,
      });
    },
  });

  useEffect(() => {
    // formik.setFieldValue("entryTitle", entry.entry_note);
    // formik.setFieldValue("text", "");
    // formik.setFieldValue("wordCount", 0);
    // formik.setFieldValue("entryDate", new Date());
  }, [entryMethod]);

  const onTextChange = (e: any, _: any, __: any, editor: any): void => {
    const count = editor.getText().trim().split(/\s+/).length;
    formik.setFieldValue("text", e);
    formik.setFieldValue("wordCount", count);
  };

  return (
    <Box mt={20}>
      <Box mb={12}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={8}
        >
          <Heading as="h2" size="xl">
            You are editing this Entry Record
          </Heading>
          <IconButton
            size="xs"
            aria-label="Back"
            color="neutral.500"
            icon={<ArrowBackIcon />}
            border={"1.2px solid"}
            p={1}
            borderRadius={"50%"}
            backgroundColor={"transparent"}
            _hover={{ bg: "none" }}
            onClick={() => setInEdit(null)}
          />
        </Box>
      </Box>

      <Grid
        mb={8}
        h="150px"
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(5, 1fr)"
        gap={4}
      >
        <GridItem rowSpan={1} colSpan={1} textAlign={"center"} mt={2}>
          <Text as="b" fontSize={"16px"}>
            Entry Date
          </Text>
          <Box
            border={"none"}
            background={"neutral.500"}
            p={"16px"}
            borderRadius={"5px"}
            height={"74px"}
            mt={2}
          >
            <Text color={"neutral.100"} px={"40px"} letterSpacing={"0.1em"}>
              {format(new Date(entry.entry_date), "MM/dd/yyyy")}
            </Text>
          </Box>
        </GridItem>
        <GridItem colSpan={4}>
          <TableContainer display={"initial"}>
            <Table variant="none">
              <Thead>
                <Tr>
                  <Th textAlign={"center"}>Time of Entry</Th>
                  <Th textAlign={"center"}>Entry Title</Th>
                  <Th textAlign={"center"}>Word Count</Th>
                  <Th textAlign={"center"}>Edit record</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr
                  background={"neutral.100"}
                  height={"74px"}
                  borderRadius={"5px"}
                  boxShadow={"1px 3px 12px rgba(0, 0, 0, 0.06)"}
                >
                  <Td
                    textAlign={"center"}
                    color={"neutral.500"}
                    fontWeight={"bold"}
                  >
                    {entry.time}
                  </Td>
                  <Td
                    textAlign={"center"}
                    color={"neutral.500"}
                    fontWeight={"bold"}
                  >
                    {entry.entry_note}
                  </Td>
                  <Td
                    textAlign={"center"}
                    color={"brand.300"}
                    fontWeight={"bold"}
                  >
                    {entry.entry_count}
                  </Td>
                  <Td display={"flex"} gap={2} width={"50%"} mx={"auto"} mt={2}>
                    <IconButton
                      size="xs"
                      aria-label="Edit"
                      icon={<FiEdit2 />}
                      border={"1.2px solid"}
                      p={1}
                      borderRadius={"50%"}
                      backgroundColor={"transparent"}
                      _hover={{ bg: "none" }}
                      isDisabled={true}
                    />
                    <IconButton
                      size="xs"
                      aria-label="Delete"
                      icon={<CloseIcon />}
                      border={"1.2px solid"}
                      p={1}
                      borderRadius={"50%"}
                      backgroundColor={"transparent"}
                      _hover={{ bg: "accents.300", color: "neutral.100" }}
                      color="accents.300"
                      borderColor={"accents.300"}
                      isDisabled={true}
                    />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </GridItem>
      </Grid>

      <Box mb={4}>
        <Grid
          templateColumns={[
            "repeat(3, 1fr)",
            "repeat(3, 1fr)",
            "repeat(6, 1fr)",
          ]}
          gap={4}
        >
          <GridItem colSpan={12}>
            <Text color={"neutral.600"} fontSize={"16px"} mb={4}>
              * Choose your method of entry
            </Text>

            <Box display={"flex"} flexWrap="wrap" gap="4" w={"80%"} mt={2}>
              <Button
                className={
                  entryMethod === "paste_text"
                    ? "entry-button-active"
                    : "entry-button"
                }
                onClick={() => setEntryMethod("paste_text")}
              >
                Paste Your Text
              </Button>
              <Button
                className={
                  entryMethod === "input_word_count"
                    ? "entry-button-active"
                    : "entry-button"
                }
                onClick={() => setEntryMethod("input_word_count")}
              >
                Input Word Count
              </Button>
            </Box>
          </GridItem>
          <GridItem colSpan={12}>
            <Card maxW="5xl" m={"auto"} w={"100%"} p={"10px"}>
              <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
                <CardBody>
                  <Box w={"100%"} display={"flex"} gap={4} mb={6}>
                    <FormControl>
                      <FormLabel>Entry Date:</FormLabel>
                      <Box
                        position={"relative"}
                        color={"neutral.500"}
                        border={"1px solid"}
                        borderRadius={"10px"}
                      >
                        <DatePicker
                          selected={formik.values.entryDate}
                          onChange={(date: Date | null) => {
                            formik.setFieldValue("entryDate", date);
                          }}
                        />
                        <Box position={"absolute"} right={"15px"} top={"7px"}>
                          <CalendarIcon />
                        </Box>
                      </Box>
                    </FormControl>
                    {/* Entry Title Field */}
                    <FormControl>
                      <FormLabel
                        size={"sm"}
                        color={"neutral.700"}
                        fontWeight="bold"
                      >
                        Entry Title
                      </FormLabel>
                      <Input
                        type="text"
                        name="entryTitle"
                        id="entryTitle"
                        placeholder="Add Entry Name"
                        border={"1px solid"}
                        value={formik.values.entryTitle}
                        onChange={formik.handleChange}
                      />
                    </FormControl>
                  </Box>
                  {entryMethod === "paste_text" ? (
                    <FormControl>
                      <FormLabel
                        size={"sm"}
                        color={"neutral.700"}
                        fontWeight="bold"
                      >
                        Enter your text
                      </FormLabel>
                      <ReactQuill
                        theme="snow"
                        value={formik.values.text}
                        onChange={onTextChange}
                        // style={{ height: "450px" }}
                      />
                      <Text
                        mt={2}
                        fontWeight={"bold"}
                        color={"brand.300"}
                        fontSize={"16px"}
                      >
                        Word Count: {formik.values.wordCount}
                      </Text>
                    </FormControl>
                  ) : (
                    <FormControl>
                      <FormLabel
                        size={"sm"}
                        color={"neutral.700"}
                        fontWeight="bold"
                      >
                        Enter Word Count
                      </FormLabel>
                      <Input
                        type="number"
                        name="wordCount"
                        id="wordCount"
                        placeholder="0"
                        border={"1px solid"}
                        value={formik.values.wordCount}
                        onChange={formik.handleChange}
                      />
                    </FormControl>
                  )}
                </CardBody>

                {/* Footer */}
                <CardFooter>
                  <Box display={"flex"} w={"100%"} gap={2}>
                    <Button
                      borderRadius={"100px"}
                      p={"16px 24px"}
                      h={"46px"}
                      w={"-webkit-fill-available"}
                      background={"#fff"}
                      color={"accents.300"}
                      _hover={{ bg: "accents.400", color: "#fff" }}
                      border={"1px solid"}
                      variant="solid"
                      onClick={() => setCancelModal(true)}
                    >
                      Cancel
                    </Button>
                    <Button
                      borderRadius={"100px"}
                      p={"16px 24px"}
                      h={"46px"}
                      w={"100%"}
                      background={"brand.300"}
                      color={"#fff"}
                      _hover={{ bg: "brand.400" }}
                      variant="solid"
                      type="submit"
                    >
                      Save
                    </Button>
                  </Box>
                </CardFooter>
                {error && (
                  <Box mx={6} my={4}>
                    <ErrorMessage
                      msg={"Oops! Something went wrong. Please Try Again."}
                    />
                  </Box>
                )}
              </form>
            </Card>
          </GridItem>
        </Grid>
      </Box>

      <CancelModal
        open={cancelModal}
        setOpen={setCancelModal}
        setInEdit={setInEdit}
      />

      <SuccessModal
        open={successModal}
        setOpen={setSuccessModal}
        text={"Changes saved Successfully"}
      />
    </Box>
  );
};

export default EditEntry;
