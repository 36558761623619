import { Box, ListItem, UnorderedList } from "@chakra-ui/react";
import { Heading } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";

const PrivacyPolicy = () => {
  return (
    <Box pt={12} px={8}>
      <Heading as="h1" size="2xl" py={2}>
        Privacy Policy
      </Heading>
      <Heading as="h2" size="lg" py={2}>
        Last Updated: August 23, 2023
      </Heading>
      <Text pb={6}>
        We take your privacy and the privacy of your information seriously. This
        Privacy Policy describes what information gets collected, how the
        information may be used, and with whom. We reserve the right to change,
        mofify or update this privacy policy at any anytime with or without
        notice. Your continued use of the service constitutes your acceptance of
        these changes. Please check back regularly as this web page will contain
        any such updates.
      </Text>

      <Heading as="h1" size="2xl" py={4}>
        Why We Collect Information
      </Heading>
      <Text>We collect your information to aid in the following items:</Text>
      <UnorderedList pb={6}>
        <ListItem>Compile aggregate data</ListItem>
        <ListItem>Provide for account identity and management</ListItem>
        <ListItem>Email you regarding your account</ListItem>
        <ListItem>Handling transactions for your account</ListItem>
        <ListItem>Investigate bugs and issues</ListItem>
      </UnorderedList>

      <Heading as="h1" size="2xl" py={4}>
        Information We Collect
      </Heading>
      <Text>
        We collect two broad types of information whenever you use the site:
      </Text>
      <UnorderedList pb={6}>
        <ListItem>
          Information about how you access the site such as your IP address,
          device type, and broad location information.
        </ListItem>
        <ListItem>
          Personal Information such as your email and login info.
        </ListItem>
      </UnorderedList>
      <Text>
        This data is typically kept for the duration of your account being
        active. If you wish for use to delete this information please email us
        about closing your account at support@writeprogress.com
      </Text>

      <Heading as="h1" size="2xl" py={4}>
        How We Collect Information
      </Heading>
      <Text>
        Much of the information we collect is provided to us by you such as when
        you register for an account or login. We also use cookies to track
        things like device types and page views as detailed below in our cookie
        policy.
      </Text>

      <Heading as="h1" size="2xl" py={4}>
        Cookies
      </Heading>
      <Text pb={6}>
        We use cookies to gather data about your interactions with our site.
        Cookies are small files that are placed on your computer to collect log
        information. This is a standard practice across many other websites. We
        use cookies to help us track user interactions and improve our
        application. You can disable cookies in your browers settings which will
        not effect the functionality of the application.
      </Text>

      <Heading as="h1" size="2xl" py={4}>
        How We Share Your Information
      </Heading>
      <Text pb={4}>
        We take the privacy of your information seriously, and will never sell
        it. We may however share your data in the following ways:
      </Text>
      <Heading as="h1" size="md" py={2}>
        With Analytics and Marketing Services
      </Heading>
      <Text>
        We may share your information in aggregate form to help us learn about
        our visitors and their habits. Services like Mixpanel allows us to track
        broad metrics on our site, and we may share non-identifiable information
        with these services.
      </Text>

      <Heading as="h1" size="md" py={2}>
        As Required By Law
      </Heading>
      <Text pb={6}>
        We may share your information as required by law or if we believe it is
        the best interest of saftey to share such information.
      </Text>

      <Heading as="h1" size="md" py={2}>
        In Billing
      </Heading>
      <Text pb={6}>
        We share your information with our{" "}
        <a href="http://stripe.com" target="_blank">
          3rd party payment processor stripe
        </a>{" "}
        in order to process billing and subscription payments.
      </Text>

      <Heading as="h1" size="2xl" py={4}>
        Data Security
      </Heading>
      <Text pb={6}>
        While we take reasonable to ensure the saftey of your data no method is
        fullproof. Therefore, we can't guarantee complete saftey of any
        information you share with us, nor are we liable for any instances of
        where that information is made public. We urge you NOT to store any
        sensitive information like bank account numbers or social security
        numbers in the Write Progress software. Data is stored on Amazon Web
        services servers and we make sure to follow modern best practices for
        things like encrypted connections and storing data at rest.
      </Text>

      <Heading as="h1" size="2xl" py={4}>
        Questions?
      </Heading>
      <Text pb={6}>
        If you have any questions or concerns with the above policies you can
        contact us at <a href="#">support@writeprogress.com</a>
      </Text>
    </Box>
  );
};

export default PrivacyPolicy;
