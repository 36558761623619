import { Box, Heading, Text } from "@chakra-ui/react";
import { Container } from "@chakra-ui/react";
import { CONTAINER_WIDTH } from "../constants";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Box pt={8} pb={[32, 32, 8]}>
      <Container px={12} maxW={CONTAINER_WIDTH}>
        <hr style={{ border: "1px solid #2D3330" }} />
        <Box display="flex" justifyContent={"space-between"} mt={8}>
          <Heading as="h1" fontSize={"20px"}>
            WriteProgress
          </Heading>
          <Box textAlign={"center"} fontWeight="bold" display={"flex"} gap={8}>
            <Link to="/privacy">
              <Text color={"neutral.800"} fontSize={"16px"}>
                Privacy Policy
              </Text>
            </Link>
            <Link to="/terms">
              <Text color={"neutral.800"} fontSize={"16px"}>
                Terms of Use
              </Text>
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
