import { useState } from "react";
import {
  Box,
  Card,
  CardFooter,
  Flex,
  Heading,
  Button,
  IconButton,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useFormik } from "formik";
import GoalItem from "./GoalItem";
import { useMutation } from "react-query";
import { onEdit } from "../../scripts/api/goals";
import { useQueryClient } from "react-query";

import GoalFrom from "./components/GoalForm";
import ErrorMessage from "../../components/ErrorMessage";
import CancelModal from "../../components/CancelModal";
import SuccessModal from "../../components/SuccessModal";

interface EditGoalProps {
  goal: Goal;
  setInEdit: Function;
}

const EditGoal = ({ goal, setInEdit }: EditGoalProps) => {
  const queryClient = useQueryClient();
  const isEdit = true;

  const [cancelModal, setCancelModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [error, setError] = useState(false);

  const formik = useFormik({
    initialValues: {
      goalName: isEdit ? goal?.goal_note : "",
      wordCount: isEdit ? goal?.goal_count : 0,
      startDate: isEdit ? new Date(goal.goal_start_date) : new Date(),
      endDate: isEdit ? new Date(goal.goal_date) : new Date(),
    },
    onSubmit: (values: any) => {},
  });

  const EditMutation = useMutation(onEdit, {
    onSuccess: (res: any) => {
      setCancelModal(false);
      setSuccessModal(true);

      queryClient.setQueryData(["getGoals"], (data: any) => {
        // If we make hte dates editable we'll need to update this too
        const newData = data.data.map((aGoal: any) => {
          if (aGoal.goal_id == goal.goal_id) {
            return {
              ...aGoal,
              goal_count: formik.values.wordCount,
              goal_note: formik.values.goalName,
            };
          } else {
            return aGoal;
          }
        });
        return { ...data, data: newData };
      });

      setTimeout(function () {
        setSuccessModal(false);
        setInEdit(null);
      }, 1000);
    },
    onError: (err: any) => {
      setError(true);
    },
  });

  return (
    <Box
      position="absolute"
      zIndex={20}
      pb={4}
      backgroundColor="#f8fafc"
      width="100%"
    >
      {/* Heading */}

      <Box mb={12}>
        <Box
          display="flex"
          alignItems="end"
          justifyContent="space-between"
          mb={8}
        >
          <Heading as="h2" size="xl" mt={4}>
            You are editing this Goal
          </Heading>
          <IconButton
            size="xs"
            aria-label="Back"
            color="neutral.500"
            icon={<ArrowBackIcon />}
            border={"1.2px solid"}
            p={1}
            borderRadius={"50%"}
            backgroundColor={"transparent"}
            _hover={{ bg: "none" }}
            onClick={() => setInEdit(null)}
          />
        </Box>
        <hr style={{ border: "1px solid #2D3330" }} />
      </Box>

      <Box display={["block", "block", "flex"]}>
        <Box
          display={["flex", "flex", "block"]}
          justifyContent={"center"}
          style={{ marginTop: "-28px" }}
        >
          <GoalItem goal={goal} isEdit={true} />
        </Box>
        <Box mt={[8, 8, 0]}>
          <Card maxW="lg" m={"auto"} w={"100%"} p={"10px"}>
            <GoalFrom formikRef={formik} />

            {/* Footer */}
            <CardFooter>
              <Flex w={"100%"} gap={2}>
                <Button
                  borderRadius={"100px"}
                  p={"16px 24px"}
                  h={"46px"}
                  w={"100%"}
                  background={"#fff"}
                  color={"accents.300"}
                  _hover={{ bg: "accents.400", color: "#fff" }}
                  border={"1px solid"}
                  variant="solid"
                  onClick={() => setCancelModal(true)}
                >
                  Cancel Edit
                </Button>
                <Button
                  borderRadius={"100px"}
                  p={"16px 24px"}
                  h={"46px"}
                  w={"100%"}
                  background={"brand.300"}
                  color={"#fff"}
                  _hover={{ bg: "brand.400" }}
                  variant="solid"
                  onClick={() =>
                    EditMutation.mutate({
                      ...goal,
                      goal_count: formik.values.wordCount,
                      goal_note: formik.values.goalName,
                      goal_start_date: formik.values.startDate,
                      goal_date: formik.values.endDate,
                    })
                  }
                >
                  Save Edit
                </Button>
              </Flex>
            </CardFooter>
            {error && (
              <Box mx={6} my={4}>
                <ErrorMessage
                  msg={"Oops! Something went wrong. Please Try Again. "}
                />
              </Box>
            )}
          </Card>
        </Box>
      </Box>

      {/* Confirm Edit Modal */}
      <CancelModal
        open={cancelModal}
        setOpen={setCancelModal}
        setInEdit={setInEdit}
      />

      {/* Edit Success Modal */}
      <SuccessModal
        open={successModal}
        setOpen={setSuccessModal}
        text={isEdit ? "Changes saved Successfully" : "Goal Added Successfully"}
      />
    </Box>
  );
};

export default EditGoal;
