import { CloseIcon } from "@chakra-ui/icons";
import { Box, Button, Divider, IconButton, Text } from "@chakra-ui/react";
import format from "date-fns/format";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";

interface EntriesTableProps {
  entries: any[];
  setInEdit: any;
  setInView?: any;
  confirmDelete?: any;
  setConfirmDelete?: any;
  onDelete?: any;
  hideControls?: boolean;
}

const groupByDates = (dates: any): any => {
  const groups = dates.reduce((groups: any, item: any) => {
    const group = groups[item.date] || [];
    group.push(item);
    groups[item.date] = group;
    return groups;
  }, {});

  return groups;
};

const EntriesTable = ({
  entries,
  setInEdit,
  setInView,
  confirmDelete,
  setConfirmDelete,
  onDelete,
  hideControls,
}: EntriesTableProps) => {
  const sortedEntries = Object.entries(groupByDates(entries));
  return (
    <table>
      <thead>
        <tr
          style={{
            fontSize: "16px",
            fontWeight: 700,
          }}
        >
          <th>Entry Date</th>
          <th>TIME OF ENTRY</th>
          <th>ENTRY TITLE</th>
          <th>WORD COUNT</th>
          {!hideControls && <th>EDIT RECORD</th>}
        </tr>
      </thead>
      <tbody>
        {sortedEntries.map((entryList: any, group: number) => (
          <>
            {entryList[1].map((entry: any, i: number) => (
              <>
                <tr
                  style={{ margin: "16px 0" }}
                  className={`entry-row ${
                    confirmDelete && confirmDelete !== entry.entry_id
                      ? "blur"
                      : ""
                  }`}
                >
                  {i === 0 && (
                    <td
                      rowSpan={entryList[1].length}
                      style={{
                        color: "white",
                        backgroundColor:
                          group % 2 === 0 ? "#49C883" : "#DD4C5D",
                      }}
                    >
                      {format(new Date(entry.entry_date), "MM/dd/yyyy")}
                    </td>
                  )}
                  <td style={{ color: "#99A59D" }}>{entry.time}</td>
                  <td style={{ color: "#99A59D" }}>{entry.entry_note}</td>
                  <td style={{ color: "#49C883" }}>{entry.entry_count}</td>
                  {!hideControls && (
                    <td>
                      <IconButton
                        size="xs"
                        aria-label="Edit"
                        icon={<AiOutlineEye />}
                        border={"1.2px solid"}
                        p={1}
                        mr={2}
                        borderRadius={"50%"}
                        backgroundColor={"transparent"}
                        _hover={{ bg: "brand.300", color: "neutral.100" }}
                        isDisabled={confirmDelete}
                        onClick={() => setInView(entry)}
                      />
                      <IconButton
                        size="xs"
                        aria-label="Edit"
                        icon={<FiEdit2 />}
                        border={"1.2px solid"}
                        p={1}
                        mr={2}
                        borderRadius={"50%"}
                        backgroundColor={"transparent"}
                        _hover={{ bg: "brand.300", color: "neutral.100" }}
                        isDisabled={confirmDelete}
                        onClick={() => setInEdit(entry)}
                      />
                      <IconButton
                        size="xs"
                        aria-label="Delete"
                        icon={<CloseIcon />}
                        border={"1.2px solid"}
                        p={1}
                        borderRadius={"50%"}
                        backgroundColor={"transparent"}
                        _hover={{ bg: "accents.300", color: "neutral.100" }}
                        color="accents.300"
                        borderColor={"accents.300"}
                        isDisabled={confirmDelete}
                        onClick={() => setConfirmDelete(entry.entry_id)}
                      />
                    </td>
                  )}
                </tr>
                {confirmDelete === entry.entry_id && (
                  <tr className="entry-delete">
                    <td colSpan={5}>
                      <Box>
                        <Box mx={4}>
                          <Divider orientation="horizontal" />
                        </Box>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          w={"100%"}
                          px={[0, 0, 12]}
                        >
                          <Text fontSize={"15px"} fontWeight={"bold"} w={"40%"}>
                            Are you sure you want to delete this entry?{" "}
                          </Text>

                          <Box
                            display={"flex"}
                            my={4}
                            w={"60%"}
                            gap={2}
                            justifyContent={"end"}
                          >
                            <Button
                              borderRadius={"100px"}
                              p={"16px 24px"}
                              h={"46px"}
                              w={"150px"}
                              background={"#fff"}
                              color={"accents.300"}
                              _hover={{ bg: "accents.400", color: "#fff" }}
                              border={"1px solid"}
                              variant="solid"
                              onClick={() => onDelete.mutate(entry.entry_id)}
                            >
                              Yes, Delete
                            </Button>
                            <Button
                              borderRadius={"100px"}
                              p={"16px 24px"}
                              h={"46px"}
                              w={"150px"}
                              background={"brand.300"}
                              color={"#fff"}
                              _hover={{ bg: "brand.400" }}
                              variant="solid"
                              onClick={() => setConfirmDelete(null)}
                            >
                              No
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </td>
                  </tr>
                )}
              </>
            ))}
          </>
        ))}
      </tbody>
    </table>
  );
};

export default EntriesTable;
