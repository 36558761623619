import {
  AddIcon,
  ArrowBackIcon,
  ArrowForwardIcon,
  CalendarIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Heading,
  Button,
  IconButton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { getEnteries } from "../../scripts/api";
import EntriesTable from "../History/components/EntriesTable";
import format from "date-fns/format";
import GoalItem from "../Goals/GoalItem";
import { getGoals, onDelete } from "../../scripts/api/goals";
import { toast } from "react-hot-toast";
import WeeklyChart from "../MyProgress/components/WeeklyChart";
import useGoals from "../../hooks/useGoals";
import { parseEntry } from "../../scripts/enteries";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import useAuth from "../../hooks/useAuth";
import Loading from "../../components/Loading";

interface EmptyCardProps {
  type: String;
}

const EmptyCard = ({ type }: EmptyCardProps) => {
  return (
    <Box
      background="white"
      boxShadow={"1px 3px 12px rgba(0, 0, 0, 0.06)"}
      rounded={"15px"}
    >
      <Box textAlign={"center"} py={10}>
        <Heading as="h1" size="md" my={2}>
          {type === "entry"
            ? "No Entries for the day"
            : "You Haven’t Set Any Goals."}
        </Heading>
        <Text
          color={"neutral.600"}
          fontSize={"16px"}
          mb={4}
          textAlign={"center"}
          margin={"auto"}
          width={"300px"}
        >
          {type === "entry"
            ? "Add your first entry for the day, and keep in touch with your writing skills."
            : "Goals will help you stay on track with your writing habits. Set your first goal Now!"}
        </Text>

        <Link to={type === "entry" ? "/entry" : "/goals"}>
          <Button
            my={8}
            borderRadius={"100px"}
            p={"16px 24px"}
            h={"46px"}
            w={"160px"}
            leftIcon={<AddIcon />}
            background={"brand.300"}
            color={"#fff"}
            _hover={{ bg: "brand.400" }}
            variant="solid"
          >
            {type === "entry" ? "New Entry" : "New Goal"}
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

const Dashboard = () => {
  const queryClient = useQueryClient();
  const { onClose } = useDisclosure();
  useAuth();

  const weekStart = addDays(startOfWeek(new Date()), 1);
  const weekEnd = addDays(endOfWeek(new Date()), 1);

  const [inEdit, setInEdit] = useState(null);

  // Pull our entries
  const entryQuery = useQuery(["getEntries"], () => getEnteries(new Date()));
  const entries =
    entryQuery.status === "success"
      ? entryQuery?.data?.data?.map(parseEntry) || []
      : [];

  // Pull our goals
  // const goalQuery = useQuery(["getGoals"], () => getGoals());
  const goalQuery = useGoals();
  const goals = goalQuery.status === "success" ? goalQuery.data?.data : [];

  const deleteMutation = useMutation(onDelete, {
    onSuccess: (res: any, id: any) => {
      toast.success("Goal Deleted");
      onClose();
      queryClient.setQueryData(["getGoals"], (data: any) => {
        const newGoals = data.data.filter((goal: Goal) => {
          return goal.goal_id !== id;
        });
        return { ...data, data: newGoals };
      });
    },
    onError: (err: any) => {
      toast.error("There was an error.");
    },
  });

  return (
    <Box>
      {/* Week Chart and Daily Entries */}
      <Box
        display={["block", "block", "block", "flex"]}
        mt={4}
        justifyContent={"space-between"}
        gap={16}
      >
        {/* Daily Entries */}
        <Box width={["100%", "100%", "100%", "50%"]}>
          <Heading as="h4" mt={4}>
            Daily Entries
          </Heading>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            my={6}
          >
            <Box display={"flex"} alignItems={"center"}>
              <IconButton
                mr={2}
                size="sm"
                aria-label="Delete"
                icon={<CalendarIcon />}
                border={"1.2px solid"}
                p={1}
                backgroundColor={"transparent"}
                borderRadius={"50%"}
                bg={"neutral.800"}
                color={"neutral.100"}
                _hover={{ bg: "neutral.800", color: "neutral.100" }}
              />
              <Text fontWeight={"bold"}>{`Today, ${format(
                new Date(),
                "MM/dd/yyyy"
              )}`}</Text>
            </Box>
            <Box>
              <Link to="/history">
                <Button
                  border={"1px solid"}
                  bg={"transparent"}
                  borderRadius={"100px"}
                  fontSize={"16px"}
                  p={"20px 30px"}
                  _hover={{ bg: "neutral.800", color: "neutral.100" }}
                >
                  View All Entries
                </Button>
              </Link>
            </Box>
          </Box>

          {entryQuery.status === "loading" && (
            <Box
              bg={"neutral.100"}
              boxShadow="1px 3px 12px rgba(0, 0, 0, 0.06)"
              borderRadius={"12px"}
            >
              <Loading text="Loading Today's Writing" />
            </Box>
          )}

          {entryQuery.status !== "loading" && (
            <Box overflow={"auto"}>
              {entries.length === 0 && <EmptyCard type={"entry"} />}
              {entries.length > 0 && (
                <EntriesTable
                  entries={entries}
                  setInEdit={setInEdit}
                  hideControls={true}
                />
              )}
            </Box>
          )}
        </Box>
        {/* Week Chart */}
        <Box width={["100%", "100%", "100%", "50%"]} pt={[8, 8, 0]}>
          <Heading as="h4" mt={4}>
            This Week’s Progress
          </Heading>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            my={6}
          >
            <Box display={"flex"} alignItems={"center"}>
              <IconButton
                mr={2}
                size="sm"
                aria-label="Delete"
                icon={<CalendarIcon />}
                border={"1.2px solid"}
                p={1}
                backgroundColor={"transparent"}
                borderRadius={"50%"}
                bg={"neutral.800"}
                color={"neutral.100"}
                _hover={{ bg: "neutral.800", color: "neutral.100" }}
              />
              <Text fontWeight={"bold"}>
                {`${format(new Date(), "MM/dd/yyyy")} - ${format(
                  new Date(),
                  "MM/dd/yyyy"
                )}`}
              </Text>
            </Box>
            <Link to="/myprogress">
              <Button
                border={"1px solid"}
                bg={"transparent"}
                borderRadius={"100px"}
                fontSize={"16px"}
                p={"20px 30px"}
                _hover={{ bg: "neutral.800", color: "neutral.100" }}
              >
                View More
              </Button>
            </Link>
          </Box>
          <WeeklyChart start={weekStart} end={weekEnd} filterType="week" />
        </Box>
      </Box>

      {/* Goals List */}
      <Box pt={8}>
        <Box
          display={["block", "block", "flex"]}
          alignItems={"center"}
          justifyContent={"space-between"}
          my={6}
        >
          <Heading as="h2">Goals In Progress</Heading>
          <Box pt={[4, 4, 0]}>
            <Link to="/goals">
              <Button
                border={"1px solid"}
                bg={"transparent"}
                borderRadius={"100px"}
                fontSize={"16px"}
                p={"20px 30px"}
                _hover={{ bg: "neutral.800", color: "neutral.100" }}
              >
                View All goals
              </Button>
            </Link>
            <Link to="/goals">
              <IconButton
                ml={4}
                size="md"
                aria-label="Delete"
                icon={<AddIcon />}
                border={"1.2px solid"}
                p={2}
                borderRadius={"50%"}
                backgroundColor={"transparent"}
                _hover={{ bg: "brand.400", color: "brand.100" }}
                bg={"transparent"}
                color={"brand.300"}
              />
            </Link>
          </Box>
        </Box>
        <Box>
          {goals.length === 0 && <EmptyCard type={"goal"} />}
          {goals.length > 0 && goalQuery.isSuccess && (
            <Box
              pt="4"
              display="flex"
              justifyContent={"space-around"}
              flexWrap="wrap"
            >
              {goals.map((goal: Goal) => (
                <GoalItem
                  goal={goal}
                  isEdit={false}
                  onEdit={setInEdit}
                  onDelete={deleteMutation}
                />
              ))}
            </Box>
          )}
        </Box>
        {/* <Box
          display={"flex"}
          gap={2}
          alignItems={"center"}
          justifyContent={"center"}
          mt={12}
          mb={4}
        >
          <IconButton
            size="xs"
            aria-label="Edit"
            icon={<ArrowBackIcon />}
            border={"1.2px solid"}
            borderRadius={"50%"}
            backgroundColor={"transparent"}
            _hover={{ bg: "none" }}
          />

          <IconButton
            size="xs"
            aria-label="Edit"
            icon={<ArrowForwardIcon />}
            border={"1.2px solid"}
            borderRadius={"50%"}
            backgroundColor={"transparent"}
            _hover={{ bg: "none" }}
          />
        </Box> */}
      </Box>
    </Box>
  );
};

export default Dashboard;
