import { Box } from "@chakra-ui/react";
import { useState } from "react";
import Header from "./components/Header";
import WeeklyChart from "./components/WeeklyChart";
import startOfWeek from "date-fns/startOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import getDaysInMonth from "date-fns/getDaysInMonth";
import useAuth from "../../hooks/useAuth";

const generateWeek = (startDate: any): any[] => {
  let week = [startDate];

  // TODO not functional
  for (let x = 1; x < 7; x++) {
    week.push(addDays(startDate, x));
  }

  return week;
};

const generateMonth = (startDate: any): any[] => {
  const firstDay = startOfMonth(startDate);
  const days = getDaysInMonth(startDate);
  let month: any = [];

  // TODO not functional
  for (let x = 0; x < days; x++) {
    month.push(addDays(firstDay, x));
  }

  return month;
};

function MyProgress() {
  useAuth();
  const weekStart = addDays(startOfWeek(new Date()), 1);
  const weekEnd = addDays(endOfWeek(new Date()), 1);

  const monthStart = startOfMonth(new Date());
  const monthEnd = endOfMonth(new Date());

  const [filterType, setFilterType] = useState("week");
  const [startDate, setStartDate] = useState(weekStart);
  const [endDate, setEndDate] = useState(weekEnd);
  const onDateChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);

    if (end) {
      setEndDate(end);
    }

    setFilterType("range");
  };

  let startDateToUse = null;
  let endDateToUse = null;

  if (filterType === "range") {
    startDateToUse = startDate;
    endDateToUse = endDate;
  } else if (filterType === "week") {
    startDateToUse = weekStart;
    endDateToUse = weekEnd;
  } else {
    startDateToUse = monthStart;
    endDateToUse = monthEnd;
  }

  return (
    <Box>
      <Header
        filterType={filterType}
        setFilterType={setFilterType}
        startDate={startDate}
        endDate={endDate}
        onDateChange={onDateChange}
      />
      <Box mb={8}>
        <WeeklyChart
          filterType={filterType}
          start={startDateToUse}
          end={endDateToUse}
        />
      </Box>
    </Box>
  );
}

export default MyProgress;
