import {
  Box,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import {
  CalendarIcon,
  ArrowBackIcon,
} from "@chakra-ui/icons";

interface GoalFormProps {
  formikRef: any; // This is a refernce to the parent's instance of formik
}

const GoalForm = ({formikRef} : GoalFormProps) => {
  const formik = formikRef;

  return (
    <Box display="flex">
      <form style={{ width: "100%" }}>
        <CardBody>
          {/* Goal Field */}
          <FormControl mb={6}>
            <FormLabel size={"sm"} color={"neutral.700"} fontWeight="bold">
              Goal Name
            </FormLabel>
            <Input
              type="text"
              name="goalName"
              id="goalName"
              placeholder="Add a Name to your Goal"
              border={"1px solid"}
              value={formik.values.goalName}
              onChange={formik.handleChange}
            />
          </FormControl>

          {/* Word Count Field */}
          <FormControl mb={6}>
            <FormLabel size={"sm"} color={"neutral.700"} fontWeight="bold">
              Word Count
            </FormLabel>
            <Input
              type="number"
              name="wordCount"
              id="wordCount"
              placeholder="Word Count you wish to reach"
              border={"1px solid"}
              value={formik.values.wordCount}
              onChange={formik.handleChange}
            />
          </FormControl>

          {/* Date Part */}
          <Flex gap={2}>
            <FormControl w={"49%"}>
              <FormLabel>Start Date:</FormLabel>
              <Box
                position={"relative"}
                color={"neutral.500"}
                border={"1px solid"}
                borderRadius={"10px"}
              >
                <DatePicker
                  selected={formik.values.startDate}
                  onChange={(date: Date | null) => {
                    formik.setFieldValue("startDate", date);
                  }}
                />
                <Box position={"absolute"} right={"15px"} top={"7px"}>
                  <CalendarIcon />
                </Box>
              </Box>
            </FormControl>

            <FormControl w={"49%"}>
              <FormLabel>End Date:</FormLabel>
              <Box
                position={"relative"}
                color={"neutral.500"}
                border={"1px solid"}
                borderRadius={"10px"}
              >
                <DatePicker
                  selected={formik.values.endDate}
                  onChange={(date: Date | null) => {
                    formik.setFieldValue("endDate", date);
                  }}
                />
                <Box position={"absolute"} right={"15px"} top={"7px"}>
                  <CalendarIcon />
                </Box>
              </Box>
            </FormControl>
          </Flex>
        </CardBody>    
      </form>
    </Box>
  );
};

export default GoalForm;
