import { useContext, useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { Container } from "@chakra-ui/react";
import { Heading } from "@chakra-ui/react";
import { CONTAINER_WIDTH } from "../constants";
import isAuth from "../hooks/isAuth";

import { Link } from "react-router-dom";
import { userTokenContext } from "../state";
import { FiMenu } from "react-icons/fi";

const Nav = () => {
  const { userToken, setUserToken } = useContext(userTokenContext);
  const [navOpen, setNavOpen] = useState(false);
  const apiToken = isAuth();

  useEffect(() => {
    if (apiToken) {
      setUserToken(apiToken);
    }
  }, [apiToken]);

  return (
    <Box
      py={5}
      background={"neutral.800"}
      boxShadow="0 .25rem .75rem rgba(0, 0, 0, .05)"
      position={"relative"}
    >
      <Container px={12} maxW={CONTAINER_WIDTH}>
        <Box
          display="flex"
          justifyContent={"space-between"}
          alignItems="center"
          color="white"
        >
          <Box pr={8}>
            <Link to="/">
              <Heading as="h2" size="lg">
                WriteProgress
              </Heading>
            </Link>
          </Box>
          <Box
            display={["block", "block", "none"]}
            position="absolute"
            right="15px"
            top="15px"
            zIndex={20}
          >
            <div onClick={() => setNavOpen(!navOpen)}>
              <FiMenu
                color={"white"}
                cursor="pointer"
                style={{ height: "48px", width: "48px" }}
              />
            </div>
          </Box>
          <Box
            className={`nav-wrapper ${navOpen ? "active" : ""}`}
            visibility={["hidden", "hidden", "visible"]}
            position={["absolute", "absolute", "relative"]}
            background={["#49C883", "#49C883", "neutral.800"]}
            top={0}
            right={0}
            opacity={[0, 0, 1]}
            style={{ borderRadius: "12px 0 0 12px" }}
            zIndex={10}
            px={[6, 6, 0]}
            pt={[16, 16, 0]}
            textAlign={["center", "center", "left"]}
          >
            {userToken ? (
              <Box display={["block", "block", "flex"]}>
                <Box pr={2} pl={2} py={[4, 4, 0]} fontSize={["xl", "xl", "md"]}>
                  {" "}
                  <Link to="/dashboard">Dashboard</Link>
                </Box>
                <Box pr={2} pl={2} py={[4, 4, 0]} fontSize={["xl", "xl", "md"]}>
                  {" "}
                  <Link to="/history">History</Link>
                </Box>
                <Box pr={2} pl={2} py={[4, 4, 0]} fontSize={["xl", "xl", "md"]}>
                  {" "}
                  <Link to="/goals">Goals</Link>
                </Box>
                <Box pr={2} pl={2} py={[4, 4, 0]} fontSize={["xl", "xl", "md"]}>
                  {" "}
                  <Link to="/contact">Feedback</Link>
                </Box>
              </Box>
            ) : (
              <Box display={["block", "block", "flex"]}>
                <Box pr={2} pl={2} py={[4, 4, 0]} fontSize={["xl", "xl", "md"]}>
                  {" "}
                  <Link to="/login">Login</Link>
                </Box>
                <Box pr={2} pl={2} py={[4, 4, 0]} fontSize={["xl", "xl", "md"]}>
                  {" "}
                  <Link to="/register">Signup</Link>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Nav;
