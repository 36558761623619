import { Box, Text } from "@chakra-ui/react";
import { BsX } from "react-icons/bs";

interface ErrorMessageProps {
    msg: String
}

const ErrorMessage = ({ msg }: ErrorMessageProps) => {
    return (
        <Box display={"flex"} alignItems={'center'}>
            <Box color={"accents.100"} backgroundColor={"accents.300"} borderRadius={"50%"} mr={2}>
                <BsX size={20} fontWeight={"bold"} />
            </Box>
            <Text color={"accents.300"} fontWeight={"bold"} mt={"1px"}>
                {msg}
            </Text>
        </Box>
    );
};

export default ErrorMessage;
