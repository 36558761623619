import React, { createContext, useState, useEffect } from "react";
import AppWrapper from "./components/AppWrapper";
import Dashboard from "./views/Dashboard/Dashboard";
import Home from "./views/Home/Home";
import Goals from "./views/Goals/Goals";
import Terms from "./views/Terms";
import { UserAuthProvider, UserTokenProvider } from "./state";
import Login from "./views/User/Login";
import Register from "./views/User/Register";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import { CONTAINER_WIDTH } from "./constants";
import { Toaster } from "react-hot-toast";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import { Container } from "@chakra-ui/react";
import ScrollTop from "./hooks/scrollTop";
import PrivacyPolicy from "./views/PrivacyPolicy";
import Contact from "./views/Contact";
import MyProgress from "./views/MyProgress/MyProgress";
import Entries from "./views/History/Entries";
import AddEntry from "./views/History/AddEntry";

import Analytics, { mpAnalyticsSetup } from "./analytics";

import "react-quill/dist/quill.snow.css";
import "react-datepicker/dist/react-datepicker.css";
import Writing from "./views/Writing";

function App() {
  mpAnalyticsSetup();

  return (
    <div style={{ minHeight: "100vh" }}>
      <UserTokenProvider>
        <Router>
          <Analytics />
          <ScrollTop />
          <Nav />
          <Toaster />
          <UserAuthProvider>
            <div style={{ minHeight: "75vh" }}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route
                  path="/dashboard"
                  element={
                    <AppWrapper>
                      <Dashboard />
                    </AppWrapper>
                  }
                />
                {/* <Route path="/history" element={<AppWrapper><History /></AppWrapper>} /> */}
                <Route
                  path="/history"
                  element={
                    <AppWrapper>
                      <Entries />
                    </AppWrapper>
                  }
                />
                <Route
                  path="/entry"
                  element={
                    <AppWrapper>
                      <AddEntry />
                    </AppWrapper>
                  }
                />
                <Route
                  path="/goals"
                  element={
                    <AppWrapper>
                      <Goals />
                    </AppWrapper>
                  }
                />
                <Route
                  path="/myprogress"
                  element={
                    <AppWrapper>
                      <MyProgress />
                    </AppWrapper>
                  }
                />

                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />

                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/contact" element={<Contact />} />
              </Routes>
            </div>
          </UserAuthProvider>
          <Footer />
        </Router>
      </UserTokenProvider>
    </div>
  );
}

export default App;
