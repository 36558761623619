import { Box } from "@chakra-ui/react";
import { Heading } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";

import DASHBOARD from "../../../images/dashboard.jpg";

const Simplicity = () => {
  return (
    <Box py={28} textAlign="center">
      <Box>
        <Heading pb={8} as="h2" size="2xl">
          Simple Dashboard
        </Heading>
        <Text
          fontSize={"1.15rem"}
          margin="0 auto"
          lineHeight="28px"
          maxWidth={"687px"}
        >
          Word Counter is an app built out of the desire to quickly and easily
          track the count of words being written. There's no better way to
          improve as a writer than writing more; word counter allows you to do
          just that.
        </Text>
      </Box>
      <Box pt={10}>
        <img
          width="80%"
          alt="Word Counter App Dashboard"
          src={DASHBOARD}
          style={{
            maxWidth: "687px",
            margin: "0 auto",
            boxShadow: "0px 3px 15px rgba(0,0,0,0.2)",
          }}
        />
      </Box>
    </Box>
  );
};

export default Simplicity;
