import format from "date-fns/format";

export const groupByDate = (rawDates: any) => {
  if (!rawDates) {
    return [];
  }

  const dates = rawDates?.map((date: any) => {
    return {
      ...date,
      entry_date: format(new Date(date.entry_date), "yyyy-MM-dd"),
    };
  });

  const grouped = dates.reduce(
    (groups: any, item: any) => ({
      ...groups,
      [item.entry_date]: [...(groups[item.entry_date] || []), item],
    }),
    {}
  );

  return grouped;
};

export const groupByDateDaily = (rawDates: any) => {
  if (!rawDates) {
    return [];
  }

  const dates = rawDates?.map((date: any) => {
    return {
      ...date,
      date: format(new Date(date.date), "yyyy-MM-dd"),
    };
  });

  const grouped = dates.reduce(
    (groups: any, item: any) => ({
      ...groups,
      [item.date]: item.count,
    }),
    {}
  );

  return grouped;
};
