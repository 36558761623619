import format from "date-fns/format";

export const getCount = (enteries: any): number => {
  return enteries.reduce((acc: number, entry: any) => {
    return acc + entry.entry_count;
  }, 0);
};

// separates out the entry date and time
export const parseEntry = (entry: any): any => {
  const newDateTime = format(
    new Date(entry.entry_date + "Z"),
    "yyyy-MM-dd'T'HH:mm:ss"
  );
  const dateTime = newDateTime.split("T");

  return {
    ...entry,
    entry_date: newDateTime,
    date: dateTime[0],
    time: dateTime[1],
  };
};

export const getTextLength = (value: string): number => {
  if (value === "") {
    return 0;
  } else {
    return value.trim().replace(/  +/g, " ").split(" ").length;
  }
};
