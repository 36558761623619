import { useState } from "react";
import { Box, Button, Text } from "@chakra-ui/react";
import { Heading } from "@chakra-ui/react";

import { groupByDate, groupByDateDaily } from "../../../scripts/dates";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import format from "date-fns/format";
import { eachDayOfInterval } from "date-fns";
import Loading from "../../../components/Loading";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import { useQuery } from "react-query";
import { getEnteriesByRange } from "../../../scripts/api";
import { Link } from "react-router-dom";
import { AddIcon } from "@chakra-ui/icons";

interface WeeklyChartProps {
  filterType: String;
  start?: any;
  end?: any;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  tooltips: { enabled: true },
  responsive: true,
  plugins: {
    legend: { display: false },
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
      },
    },
    y: {
      min: 0,
      ticks: {
        callback: function (value: any) {
          if (value == 0) {
            return "Count";
          } else {
            return value;
          }
        },
      },
      // title: {
      //   display: true,
      //   text: "Count",
      //   align: "start" as any,
      // },
      grid: { display: false },
    },
  },
};

const generateWeek = (startDate: any): any[] => {
  let week = [startDate];

  // TODO not functional
  for (let x = 1; x < 7; x++) {
    week.push(addDays(startDate, x));
  }

  return week;
};

const getDates = (startDate: any, endDate: any) => {
  return eachDayOfInterval({
    start: new Date(startDate),
    end: new Date(endDate),
  });
};

const EmptyEntries = ({ filterType }: WeeklyChartProps) => {
  return (
    <Box bg={"neutral.100"}>
      <Box textAlign={"center"} py={10}>
        <Heading as="h1" size="md" my={2}>
          {`You haven’t had any Entries ${
            filterType === "week"
              ? "this week."
              : filterType === "month"
              ? "this month."
              : "for the given range."
          }`}
        </Heading>
        <Text
          color={"neutral.600"}
          fontSize={"16px"}
          mb={4}
          textAlign={"center"}
          margin={"auto"}
        >
          {`Add your first entry for the ${
            filterType === "week"
              ? "week"
              : filterType === "month"
              ? "month"
              : "given range"
          } to see your progress.`}
        </Text>

        <Link to="/entry">
          <Button
            my={8}
            borderRadius={"100px"}
            p={"16px 24px"}
            h={"46px"}
            w={"160px"}
            leftIcon={<AddIcon />}
            background={"brand.300"}
            color={"#fff"}
            _hover={{ bg: "brand.400" }}
            variant="solid"
          >
            New Entry
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

const WeeklyChart = ({ start, end, filterType }: WeeklyChartProps) => {
  const [startDate, setStartDate] = useState(
    addDays(startOfWeek(new Date()), 1)
  );
  const [endDate, setEndDate] = useState(endOfWeek(new Date()));

  const week = getDates(start, end);
  const labels = week.map((date: any) => format(date, "E"));
  const history = useQuery(["getEnteriesByRange", startDate, endDate], () =>
    getEnteriesByRange(startDate, endDate)
  );

  const standardWeekValues = week.map((date: any) =>
    format(date, "yyyy-MM-dd")
  );
  const groupedValues = groupByDate(history.data?.data);

  const valuesPerDay = standardWeekValues.map((day: string) => {
    let dayValues = 0;
    if (groupedValues[day]) {
      dayValues = groupedValues[day].reduce((acc: number, entry: any) => {
        return acc + entry.entry_count;
      }, 0);
    }

    return dayValues;
  });

  const total = valuesPerDay.reduce(
    (acc: number, val: number, idx: number) => acc + val,
    0
  );

  const data = {
    labels,
    datasets: [
      {
        label: "Count",
        barThickness: 40,
        borderRadius: 5,
        data: valuesPerDay,
        // data: [1, 23, 1, 64, 18, 9, 191],
        backgroundColor: "green",
      },
    ],
  };

  return (
    <Box
      px={5}
      py={5}
      mt={6}
      // boxShadow={"lg"}
      boxShadow={"1px 3px 12px rgba(0, 0, 0, 0.06)"}
      rounded={"15px"}
      background="white"
      overflow={"hidden"}
    >
      <Box pt={2} display={"flex"} justifyContent={"space-between"} px={4}>
        {start && (
          <Text as="b">
            {`${format(start, "MM/dd/yyyy")} 
              - ${format(end, "MM/dd/yyyy")}
            `}
          </Text>
        )}
        <Heading
          as="h4"
          size="md"
          color={"brand.300"}
        >{`Total Count: ${total}`}</Heading>
      </Box>

      {history.status === "loading" && (
        <Box pt={4}>
          <Loading />
        </Box>
      )}

      {history.status === "success" && (
        <Box pt={4}>
          {total === 0 ? (
            <EmptyEntries filterType={filterType} />
          ) : (
            <Bar options={options} data={data} />
          )}
        </Box>
      )}
    </Box>
  );
};

export default WeeklyChart;
