import mixpanel from "mixpanel-browser";
import React, { createContext, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

export const mpAnalyticsSetup = () => {
  mixpanel.init("8f6843830ccd4d60b908d5ef5617994f");
};

export const trackEvent = (eventName: string): void => {
  mixpanel.track(eventName);
};

const Analytics = () => {
  const location = useLocation();
  useEffect(() => {
    mixpanel.track_pageview();
  }, [location.pathname]);

  return null;
};

export default Analytics;
