import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Box,
  IconButton,
  Text,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import format from "date-fns/format";
import { Prose } from "@nikolovlazar/chakra-ui-prose";

interface ViewEntryProps {
  entry: any;
  setInView: Function;
}

const ViewEntry = ({ entry, setInView }: ViewEntryProps) => {
  const text_count = entry.entry_text?.length;
  return (
    <Box mt={20}>
      <Box
        display={"flex"}
        alignContent="center"
        justifyContent={"space-between"}
      >
        <Heading as="h2" size="2xl">
          {entry.entry_note}
        </Heading>
        <IconButton
          size="xs"
          aria-label="Back"
          color="neutral.500"
          icon={<ArrowBackIcon />}
          border={"1.2px solid"}
          p={1}
          mb={12}
          float={"right"}
          borderRadius={"50%"}
          backgroundColor={"transparent"}
          _hover={{ bg: "none" }}
          onClick={() => setInView(null)}
        />
      </Box>

      <TableContainer display={"initial"}>
        <Table variant="none">
          <Thead>
            <Tr>
              <Th textAlign={"center"}>Entry Date</Th>
              <Th textAlign={"center"}>Time of Entry</Th>
              <Th textAlign={"center"}>Word Count</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr
              background={"neutral.100"}
              height={"74px"}
              borderRadius={"5px"}
              boxShadow={"1px 3px 12px rgba(0, 0, 0, 0.06)"}
            >
              <Td
                background={"#49C883"}
                textAlign={"center"}
                color={"white"}
                fontWeight={"bold"}
              >
                {format(new Date(entry.entry_date), "MM/dd/yyyy")}
              </Td>
              <Td
                textAlign={"center"}
                color={"neutral.500"}
                fontWeight={"bold"}
              >
                {entry.time}
              </Td>
              <Td textAlign={"center"} color={"brand.300"} fontWeight={"bold"}>
                {entry.entry_count}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>

      <Box my={4}>
        {text_count > 0 ? (
          <Box>
            <Box>
              <Heading as="h2" size="xl" mt={6}>
                Entry Text
              </Heading>
              <Box pt={4}>
                <Prose>
                  <div dangerouslySetInnerHTML={{ __html: entry.entry_text }} />
                </Prose>
              </Box>
            </Box>
          </Box>
        ) : (
          <Text
            color={"neutral.900"}
            fontWeight={"bold"}
          >{`Count manually entered: ${entry.entry_count}`}</Text>
        )}
      </Box>
    </Box>
  );
};

export default ViewEntry;
