import { Box } from "@chakra-ui/react";
import { Heading } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Container } from "@chakra-ui/react";
import { CONTAINER_WIDTH } from "../../constants";

import Flow from "./components/Flow";
import Simplicity from "./components/Simplicity";

import HERO from "../../images/hero.png";
import ENTRIES from "../../images/entries.jpg";
import PROGRESS from "../../images/progress.jpg";
import SETYOURGOALS from "../../images/set-your-goals.png";

const Home = () => {
  return (
    <div>
      <Box px={8} display={"flex"} maxWidth="1500px" margin="0 auto">
        <Box
          display="flex"
          flexDirection={"column"}
          justifyContent="center"
          minHeight={"90vh"}
          width={["100%", "100%", "50%"]}
        >
          <Heading as="h1" size="3xl">
            Track Your Writing.{" "}
            <Text color="brand.300" as="span">
              Become a Better Writer.
            </Text>
          </Heading>
          <Heading pt={4} color={"neutral.600"} as="h2" size="md">
            Track your word count, set goals, and develop your skills as a
            writer.
          </Heading>
          <Box pt={4}>
            <Link to="/register">
              <Button
                background={"brand.300"}
                color={"#fff"}
                padding={"16px 24px"}
                borderRadius="100px"
                _hover={{ bg: "brand.400" }}
              >
                Get Started
              </Button>
            </Link>
          </Box>
        </Box>
        <Box
          flexDirection={"column"}
          justifyContent="center"
          alignItems={"center"}
          display={["none", "none", "flex"]}
          width="50%"
        >
          <img src={HERO} alt="Write Progress Hero Image" width="70%" />
        </Box>
      </Box>

      <Container px={8} maxW={CONTAINER_WIDTH}>
        <Heading pb={12} pt={8} textAlign={"center"} as="h2" size="2xl">
          WriteProgress is going to help you with
        </Heading>
        <Flow />
      </Container>

      <Container px={8} maxW={CONTAINER_WIDTH}>
        <Simplicity />
      </Container>

      <Container px={8} mx={"auto"} maxW={CONTAINER_WIDTH}>
        <Box display={["block", "block", "Flex"]}>
          <Box width={["auto", "auto", "50%"]} maxWidth={"md"} mx="auto">
            <Heading pb={12} pt={8} textAlign={"center"} as="h2" size="2xl">
              <Text color="brand.300" as="span">
                Set your Goals.
              </Text>{" "}
              Stay Motivated.
            </Heading>
            <Text fontSize={"lg"}>
              Set your goals to help keep you motivated. Track your progress and
              see where you're at risk and where you're on track.
            </Text>
          </Box>
          <Box
            width={["auto", "auto", "50%"]}
            pl={["0", "0", "48"]}
            pt={["16", "16", "0"]}
          >
            <img
              src={SETYOURGOALS}
              width="100%"
              alt="Set your goals with WriteProgress!"
            />
          </Box>
        </Box>
      </Container>

      <Container px={8} mt={12} mx={"auto"} maxW={CONTAINER_WIDTH}>
        <Box
          display="flex"
          flexDirection={["column-reverse", "column-reverse", "row"]}
        >
          <Box
            width={["auto", "auto", "50%"]}
            pr={["0", "0", "48"]}
            pt={["16", "16", "0"]}
          >
            <img
              src={ENTRIES}
              width="100%"
              style={{
                borderTop: "4px solid #82F4B6",
              }}
              alt="Set your goals with WriteProgress!"
            />
          </Box>
          <Box width={["auto", "auto", "50%"]} maxWidth={"md"} mx="auto">
            <Heading pb={12} pt={8} textAlign={"center"} as="h2" size="2xl">
              <Text color="brand.300" as="span">
                Add Entries
              </Text>{" "}
              Record Your Progress.
            </Heading>
            <Text fontSize={"lg"}>
              Quickly and easily add entries to your log. We make it simple so
              you can spend less time tracking and more time writing!
            </Text>
          </Box>
        </Box>
      </Container>

      <Container mt={12} px={8} mx={"auto"} maxW={CONTAINER_WIDTH}>
        <Box display={["block", "block", "Flex"]}>
          <Box width={["auto", "auto", "50%"]} maxWidth={"md"} mx="auto">
            <Heading pb={12} pt={8} textAlign={"center"} as="h2" size="2xl">
              <Text color="brand.300" as="span">
                Track.
              </Text>{" "}
              Your Weeks.
            </Heading>
            <Text fontSize={"lg"}>
              Keep tabs on your progres and see how you improve week over week
              and month over month.
            </Text>
          </Box>
          <Box
            width={["auto", "auto", "50%"]}
            pl={["0", "0", "48"]}
            pt={["16", "16", "0"]}
          >
            <img
              src={PROGRESS}
              width="100%"
              alt="Set your goals with WriteProgress!"
            />
          </Box>
        </Box>
      </Container>

      <Box bg={"neutral.300"} py={32} mt={12}>
        <Container
          px={8}
          mx={"auto"}
          maxW={CONTAINER_WIDTH}
          textAlign={"center"}
        >
          <Box maxWidth="sm" mx={"auto"}>
            <Heading as="h2" size="2xl" pb={6}>
              Get Started For Free
            </Heading>
            <Text fontSize={"lg"}>
              WriteProgress is free to start using. With more features on the
              way, now is always the best time to start writing.
            </Text>
            <Link to="/register">
              <Button
                mt={12}
                background={"brand.300"}
                color={"#fff"}
                padding={"16px 24px"}
                borderRadius="100px"
                _hover={{ bg: "brand.400" }}
              >
                Get Started
              </Button>
            </Link>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default Home;
