import { useState } from "react";
import { Box, Text, Progress, IconButton, Button } from "@chakra-ui/react";
import format from "date-fns/format";
import { FiEdit2 } from "react-icons/fi";
import { AiTwotoneQuestionCircle } from "react-icons/ai";
import { CloseIcon, StarIcon } from "@chakra-ui/icons";

interface GoalProps {
  goal: Goal;
  isEdit: boolean;
  onEdit?: Function;
  onDelete?: any;
  isDelete?: any;
  setIsDelete?: any;
}

type StatusTypes =
  | "in_progress"
  | "future"
  | "completed"
  | "deadline_reached"
  | "not_started";

const getStatus = (
  count: number | undefined,
  goalCount: number,
  startDate: any,
  date: any
): StatusTypes => {
  const today = new Date().valueOf();
  const newCount = count || 0;

  if (startDate > today) {
    return "not_started";
  }

  if (date > today) {
    return "in_progress";
  }

  if (today > date && goalCount > newCount) {
    return "deadline_reached";
  } else {
    return "completed";
  }
};

const CardHeader = ({ status }: any): any => {
  switch (status) {
    case "in_progress":
      return <Box bg="brand.300" h={"5px"} borderRadius="8px 8px 0 0" />;
    case "deadline_reached":
      return (
        <Box
          bg="accents.300"
          h={"39px"}
          borderRadius="8px 8px 0 0"
          color={"brand.100"}
          textAlign={"center"}
          p={"9px"}
        >
          <Text fontSize={"14px"}>Deadline Reached</Text>
        </Box>
      );

    case "completed":
      return (
        <Box
          display="flex"
          bg="brand.300"
          h={"39px"}
          borderRadius="8px 8px 0 0"
          color={"brand.100"}
          justifyContent={"center"}
          gap={2}
          p={"9px"}
        >
          <StarIcon
            style={{
              width: "16px",
              height: "16px",
            }}
          />
          <Text fontSize={"14px"}>Goal Completed</Text>
        </Box>
      );
  }
};

const GoalItem = ({
  goal,
  isEdit,
  onEdit,
  onDelete,
  isDelete,
  setIsDelete,
}: GoalProps) => {
  const [confirmDelete, setConfirmDelete] = useState(false);

  const status: StatusTypes = getStatus(
    goal.current_count,
    goal.goal_count,
    goal.goal_start_date,
    goal.goal_date
  ) as StatusTypes;

  const progressOrFuture = status === "in_progress" || status === "not_started";

  return (
    <Box
      width={"330px"}
      borderRadius="8px"
      mt={8}
      mx={2}
      className={goal.goal_id !== isDelete ? "blur-card" : ""}
    >
      <CardHeader status={status} />

      <Box
        alignItems={"flex-start"}
        p={"20px"}
        bg={"neutral.100"}
        boxShadow={"1px 3px 15px rgba(0, 0, 0, 0.08)"}
      >
        <Box mb={6}>
          <Box display="flex" justifyContent={"space-between"}>
            <Text as="b">{goal.goal_note}</Text>
            <Box display={"flex"} gap={2}>
              {onEdit && (
                <IconButton
                  size="xs"
                  aria-label="Edit"
                  icon={<FiEdit2 />}
                  border={"1.2px solid"}
                  p={1}
                  borderRadius={"50%"}
                  backgroundColor={"transparent"}
                  _hover={{ bg: "none" }}
                  isDisabled={isEdit || confirmDelete}
                  onClick={() => onEdit(goal)}
                />
              )}
              {onDelete && (
                <IconButton
                  size="xs"
                  aria-label="Delete"
                  icon={<CloseIcon />}
                  border={"1.2px solid"}
                  p={1}
                  borderRadius={"50%"}
                  backgroundColor={"transparent"}
                  _hover={{ bg: "accents.300", color: "neutral.100" }}
                  color="accents.300"
                  borderColor={"accents.300"}
                  isDisabled={isEdit || confirmDelete}
                  onClick={() => {
                    setConfirmDelete(true);
                    setIsDelete(goal.goal_id);
                  }}
                />
              )}
            </Box>
          </Box>
          {progressOrFuture && (
            <Box
              display={"flex"}
              color={status === "in_progress" ? "brand.300" : "#5389c1"}
              border={"none"}
              p={"8px 12px"}
              w={"fit-content"}
              bg={"brand.100"}
              borderRadius={"100px"}
              gap={2}
              alignItems={"center"}
            >
              {status === "in_progress" && (
                <AiTwotoneQuestionCircle
                  style={{ height: "12px", width: "12px" }}
                />
              )}
              <Text fontSize={"12px"} as="b">
                {status === "in_progress" ? "IN PROGRESS" : "FUTURE GOAL"}
              </Text>
            </Box>
          )}
        </Box>
        <Box
          border={"1.5px solid"}
          textAlign={"center"}
          py={2}
          color={"neutral.600"}
          borderRadius={"8px"}
          mb={6}
        >
          <Text as="b" fontSize={"16px"}>
            Goal: {goal.goal_count} Words
          </Text>
        </Box>

        <Box display={"flex"} gap={2} mb={6}>
          <Box w={"100%"} as="b">
            <Text color={"neutral.600"} fontSize={"15px"}>
              Start Date
            </Text>
            <Text fontSize={"16px"}>
              {format(new Date(goal.goal_start_date), "MM/dd/yyyy")}
            </Text>
          </Box>
          <Box w={"100%"} as="b">
            <Text color={"neutral.600"} fontSize={"15px"}>
              End Date
            </Text>
            <Text fontSize={"16px"}>
              {format(new Date(goal.goal_date), "MM/dd/yyyy")}
            </Text>
          </Box>
        </Box>

        <hr />
        <Box mt={6} mb={4}>
          <Text fontSize={"14px"} as="b">
            PROGRESS
          </Text>
          <Box
            display={"flex"}
            as="b"
            color={"neutral.600"}
            justifyContent={"space-between"}
          >
            <Text>0</Text>
            <Text>{goal.goal_count}</Text>
          </Box>
          <Progress
            colorScheme={status === "deadline_reached" ? "red" : "green"}
            size="sm"
            value={goal.current_count}
            min={0}
            max={goal.goal_count}
            borderRadius={"100px"}
            h={"12px"}
            mb={4}
          />
          <Text
            fontSize={"18px"}
            as="b"
            color={status === "deadline_reached" ? "accents.300" : "brand.300"}
          >
            Current Count: {goal.current_count}
          </Text>
        </Box>
        {confirmDelete && (
          <Box>
            <hr />
            <Text fontSize={"15px"} fontWeight={"bold"} w={"90%"} mt={4}>
              Are you sure you want to delete this entry?{" "}
            </Text>
            <Box display={"flex"} my={4} w={"100%"} gap={2}>
              <Button
                borderRadius={"100px"}
                p={"16px 24px"}
                h={"46px"}
                w={"100%"}
                background={"#fff"}
                color={"accents.300"}
                _hover={{ bg: "accents.400", color: "#fff" }}
                border={"1px solid"}
                variant="solid"
                onClick={() => {
                  onDelete.mutate(goal.goal_id);
                  setConfirmDelete(false);
                  setIsDelete(null);
                }}
              >
                Yes, Delete
              </Button>
              <Button
                borderRadius={"100px"}
                p={"16px 24px"}
                h={"46px"}
                w={"100%"}
                background={"brand.300"}
                color={"#fff"}
                _hover={{ bg: "brand.400" }}
                variant="solid"
                onClick={() => {
                  setConfirmDelete(false);
                  setIsDelete(null);
                }}
              >
                No
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default GoalItem;
