import { extendTheme } from "@chakra-ui/react";
import { withProse } from "@nikolovlazar/chakra-ui-prose";

// 2. Call `extendTheme` and pass your custom values
export const theme = extendTheme(
  {
    colors: {
      neutral: {
        50: "#F9FBFC",
        100: "#FFFFFF",
        200: "#F5FCF7",
        300: "#D8E5DD",
        400: "#B7C5BB",
        500: "#99A59D",
        600: "#7A867E",
        700: "#525E57",
        800: "#2D3330",
      },
      brand: {
        100: "#F5FFFA",
        200: "#82F4B6",
        300: "#49C883",
        400: "#309E62",
      },
      accents: {
        100: "#FFF3F4",
        200: "#F995A1",
        300: "#DD4C5D",
        400: "#AA2D3C",
      },
    },

    fonts: {
      heading: `'Montserrat', sans-serif`,
      body: `'Hind', sans-serif`,
    },

    components: {
      Heading: {
        sizes: {
          "2xl": { fontSize: "2.5rem", lineHeight: "120%" },
          xl: { fontSize: "2rem", lineHeight: "120%" },
          md: { fontSize: "1.125rem" },
        },
      },
      Text: {
        size: {
          xs: { fontSize: "0.8125rem" },
        },
      },
    },
  },
  withProse()
);
