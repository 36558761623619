import { CheckIcon } from "@chakra-ui/icons"
import { Box, Modal, ModalBody, ModalContent, ModalOverlay, Text } from "@chakra-ui/react"

interface SuccessModalProps {
    open: boolean, setOpen: Function, text: String
}

const SuccessModal = ({ open, setOpen, text }: SuccessModalProps) => {
    return (
        <Modal
            onClose={() => setOpen(false)}
            size={"sm"}
            isOpen={open}
            isCentered
        >
            <ModalOverlay />
            <ModalContent>
                <ModalBody>
                    <Box textAlign={"center"} my={8}>
                        <Box
                            bg="brand.300"
                            color="brand.100"
                            borderRadius="50%"
                            margin={"auto"}
                            width={"fit-content"}
                            padding={"10px"}
                            mb={6}
                        >
                            <CheckIcon boxSize={8} />
                        </Box>
                        <Text fontSize={"20px"} fontWeight={"bold"} mb={2}>
                            {text}
                        </Text>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default SuccessModal