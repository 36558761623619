import { Box, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { useLocation } from "react-router-dom";
import { HiOutlineHome } from "react-icons/hi";
import { HiListBullet } from "react-icons/hi2";
import { AiOutlineDownload, AiOutlineLineChart } from "react-icons/ai";
import { FiTarget } from "react-icons/fi";

interface IconProps {
  children?: React.ReactNode;
  active?: boolean;
}

const IconWrapper = ({ children, active }: IconProps) => {
  const bg = active ? "brand.300" : "white";

  return (
    <Box
      w="48px"
      h="48px"
      borderRadius={"100%"}
      border="2px solid #49C883"
      bg={bg}
      textAlign={"center"}
      display="flex"
      justifyContent={"center"}
      alignItems="center"
    >
      {children}
    </Box>
  );
};

const Sidebar = () => {
  const location = useLocation();
  const path = location.pathname;

  return (
    <Box
      mr={[0, 5, 5, 5]}
      bg="white"
      position={["fixed", "fixed", "relative"]}
      width={["100%", "100%", "auto"]}
      zIndex="5"
      pr={4}
      pl={4}
      paddingTop={[5, 5, "72px"]}
      style={{ boxShadow: "2px 0px 12px rgba(0, 0, 0, 0.04)" }}
      bottom="0"
    >
      <Box
        alignItems={"center"}
        justifyContent="center"
        display="flex"
        flexDirection={["row", "row", "column"]}
      >
        <Link to="/dashboard">
          <Box
            mb={5}
            textAlign="center"
            display="flex"
            flexDirection={"column"}
            pr={[7, 7, 0]}
          >
            <IconWrapper active={path === "/dashboard"}>
              <HiOutlineHome
                color={path === "/dashboard" ? "white" : "#49C883"}
                style={{ height: "24px", width: "24px" }}
              />
            </IconWrapper>
            <Text mt={2} as="b">
              Home
            </Text>
          </Box>
        </Link>

        <Link to="/history">
          <Box
            mb={5}
            textAlign="center"
            alignItems={"center"}
            display="flex"
            flexDirection={"column"}
            pr={[7, 7, 0]}
            pl={[7, 7, 0]}
          >
            <IconWrapper active={path === "/history"}>
              <HiListBullet
                color={path === "/history" ? "white" : "#49C883"}
                style={{ height: "24px", width: "24px" }}
              />
            </IconWrapper>
            <Text mt={2} as="b">
              Entries
            </Text>
          </Box>
        </Link>

        <Link to="/goals">
          <Box
            mb={5}
            textAlign="center"
            alignItems={"center"}
            display="flex"
            flexDirection={"column"}
            pr={[7, 7, 0]}
            pl={[7, 7, 0]}
          >
            <IconWrapper active={path === "/goals"}>
              <FiTarget
                color={path === "/goals" ? "white" : "#49C883"}
                style={{ height: "24px", width: "24px" }}
              />
            </IconWrapper>
            <Text mt={2} as="b">
              Goals
            </Text>
          </Box>
        </Link>

        <Link to="/myprogress">
          <Box
            textAlign="center"
            alignItems={"center"}
            display="flex"
            flexDirection={"column"}
            pl={[7, 7, 0]}
          >
            <IconWrapper active={path === "/myprogress"}>
              <AiOutlineLineChart
                color={path === "/myprogress" ? "white" : "#49C883"}
                style={{ height: "24px", width: "24px" }}
              />
            </IconWrapper>
            <Text mb={5} mt={2} as="b">
              Progress
            </Text>
          </Box>
        </Link>
      </Box>
    </Box>
  );
};

export default Sidebar;
