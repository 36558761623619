import { Box, Heading } from "@chakra-ui/react";
import { PiSpinnerBold } from "react-icons/pi";

interface LoadingProps {
  text?: string | undefined;
}

const Loading = ({ text }: LoadingProps) => {
  const displayText = text || "Loading";
  return (
    <Box py={4} textAlign={"center"}>
      <Heading as="h3">{displayText}</Heading>
      <Box display={"flex"} justifyContent="center" pt={2}>
        <PiSpinnerBold className="rotating" fontSize={"48px"} />
      </Box>
    </Box>
  );
};

export default Loading;
