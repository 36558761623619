import {
  Box,
  Container
} from "@chakra-ui/react";


import Sidebar from './Sidebar';

interface AppWrapperProps {
  children?: React.ReactNode;
}

const AppWrapper = ({children}: AppWrapperProps) => {
  return (
    <Box display="flex">
      <Sidebar />
      <Container maxW={"1200px"} pr={4} pl={4}>
        {children}
      </Container>
    </Box>
  )
}

export default AppWrapper;