import { useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { Heading } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";

import { useDisclosure } from "@chakra-ui/react";

import useAuth from "../../hooks/useAuth";
import { useQuery, useMutation } from "react-query";
import { getGoals, onDelete } from "../../scripts/api/goals";
import GoalItem from "./GoalItem";
import { useQueryClient } from "react-query";
import toast from "react-hot-toast";

import { getEnteriesByRange } from "../../scripts/api";

import AddGoal from "./components/AddGoal";
import EditGoal from "./EditGoal";
import useGoals from "../../hooks/useGoals";
import { max } from "date-fns";

const EmptyGoals = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box mt={36} mb={24} textAlign={"center"}>
      <Heading as="h2" size="xl" my={2} fontSize={"36px"}>
        You Don't Have Any Goals.
      </Heading>
      <Text color={"neutral.600"} fontSize={"16px"} mb={4}>
        Try adding your first goal. Goals will keep you motivated to keep
        writing.
      </Text>
      {/* <Heading as='h3' size='md'>Try Adding Some!</Heading> */}
      <Button
        my={8}
        borderRadius={"100px"}
        p={"16px 24px"}
        h={"46px"}
        w={"160px"}
        leftIcon={<AddIcon />}
        background={"brand.300"}
        color={"#fff"}
        _hover={{ bg: "brand.400" }}
        variant="solid"
        onClick={onOpen}
      >
        New Goal
      </Button>

      <AddGoal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </Box>
  );
};

const getEntriesInRange = (
  start: number,
  end: number,
  entries: any[]
): any[] => {
  return entries.filter((entry: any) => {
    const currDate = new Date(entry.entry_date).valueOf();

    return currDate > start && currDate < end;
  });
};

const convertToTz = (goal: any) => {
  const startDate = new Date(goal.goal_start_date);
  const endDate = new Date(goal.goal_date);

  return {
    ...goal,
    goal_start_date:
      startDate.valueOf() + startDate.getTimezoneOffset() * 60 * 1000,
    goal_date: endDate.valueOf() + endDate.getTimezoneOffset() * 60 * 1000,
  };
};

const Goals = () => {
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inEdit, setInEdit] = useState(null);
  const [isDelete, setIsDelete] = useState<any>(null);
  useAuth();

  // Pull our goals
  const goalQuery = useGoals();
  const goals = goalQuery?.data?.data.map(convertToTz) || [];

  const deleteMutation = useMutation(onDelete, {
    onSuccess: (res: any, id: any) => {
      toast.success("Goal Deleted");
      onClose();

      queryClient.setQueryData(["getGoals"], (data: any) => {
        const newGoals = data.data.filter((goal: Goal) => {
          return goal.goal_id !== id;
        });
        return { ...data, data: newGoals };
      });
    },
    onError: (err: any) => {
      toast.error("There was an error.");
    },
  });

  if (goals.length === 0 && goalQuery.status !== "loading") {
    return <EmptyGoals />;
  }

  return (
    <Box pt="5" pb="10" position={"relative"}>
      <AddGoal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      {inEdit && <EditGoal goal={inEdit} setInEdit={setInEdit} />}
      <Box display="flex" alignItems={"center"}>
        <Heading as="h2" size="lg">
          My Writing Goals
        </Heading>
        <Button
          ml={4}
          leftIcon={<AddIcon />}
          colorScheme="green"
          variant="solid"
          onClick={onOpen}
        >
          Add Goal
        </Button>
      </Box>
      {goalQuery.status === "loading" && (
        <Box pt="4" textAlign="center" flexWrap="wrap">
          <Heading as="h2" size="lg">
            Loading Goals.
          </Heading>
          <Spinner mt={4} size="xl" />
        </Box>
      )}
      {goalQuery.isSuccess && (
        <Box
          pt="4"
          display="flex"
          justifyContent={"space-around"}
          flexWrap="wrap"
        >
          {goals.map((goal: Goal) => (
            <GoalItem
              goal={goal}
              isEdit={false}
              onEdit={setInEdit}
              onDelete={deleteMutation}
              isDelete={isDelete}
              setIsDelete={setIsDelete}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Goals;
