import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getEnteries } from "../../../scripts/api";
import { options } from "./utils";
import DatePicker from "react-datepicker";

interface HeaderProps {
  text: String;
  search: any;
  setSearch: Function;
  filter: any;
  setFilter: Function;
  startDate: any;
  endDate: any;
  onDateChange: any;
}

const Header = ({
  text,
  search,
  setSearch,
  filter,
  setFilter,
  startDate,
  endDate,
  onDateChange,
}: HeaderProps) => {
  const onOptionChangeHandler = (e: any) => {
    setFilter(e.target.value);
  };

  return (
    <Box my={12}>
      <Box
        display={["block", "block", "flex"]}
        alignItems="center"
        justifyContent="space-between"
        mb={8}
      >
        <Heading as="h2" size="xl" mb={[4, 4, 0]}>
          {text}
        </Heading>

        <Box display={"flex"} flexDirection={["column", "row", "row", "row"]}>
          <InputGroup>
            <Input
              placeholder="Search By Title"
              fontSize={"12px"}
              border={"2px solid"}
              borderColor={"neutral.500"}
              borderRadius={"100px"}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <InputRightElement mx={2} color={"neutral.500"}>
              <SearchIcon />
            </InputRightElement>
          </InputGroup>
          <Box mt={[4, 0, 0, 0]} ml={[0, 4, 4, 4]}>
            <DatePicker
              selected={startDate}
              onChange={onDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
            />
          </Box>
        </Box>
      </Box>
      <hr style={{ border: "1px solid" }} />
    </Box>
  );
};

export default Header;
