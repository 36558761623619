import axios from "axios";

export const formatDateShort = (date: any): string => {
  // const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  const localISOTime = date.toISOString().slice(0, -1).split("T")[0];
  return localISOTime;
};

export const formatDate = (date: any): any => {
  // const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  const localISOTime = date.toISOString().slice(0, 19).replace("T", " ");
  return localISOTime;
};

export const formatDateAfterEdit = (date: any): any => {
  const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  const localISOTime = new Date(date - tzoffset).toISOString().slice(0, 19);
  return localISOTime;
};

export const getAuthKey = (): string => {
  return localStorage.getItem("WORDCOUNTER_ACCESS_TOKEN") || "";
};

export const onSave = (entryDetails: any): any => {
  // TODO move this
  const localISOTime = formatDate(entryDetails.entry_date);
  const authKey = getAuthKey();

  return axios.post(
    `${process.env.REACT_APP_API_URL}entry`,
    { ...entryDetails, entry_date: localISOTime },
    { headers: { Authorization: `Token ${authKey}` } }
  );
};

export const onEdit = (entryDetails: any): any => {
  // check dates are differnet otherwise we'll update time :()
  const authKey = getAuthKey();
  const localISOTime = formatDate(entryDetails.entry_date);

  return axios.post(
    `${process.env.REACT_APP_API_URL}edit-entry/${entryDetails.entry_id}`,
    { ...entryDetails, entry_date: localISOTime },
    { headers: { Authorization: `Token ${authKey}` } }
  );
};

export const onDelete = (id: any): any => {
  const authKey = getAuthKey();

  return axios.delete(`${process.env.REACT_APP_API_URL}entry-delete/${id}`, {
    headers: { Authorization: `Token ${authKey}` },
  });
};

export const getEnteries = (date: any): any => {
  const localISOTime = formatDateShort(date);
  const authKey = getAuthKey();
  return axios.get(`${process.env.REACT_APP_API_URL}entry/${localISOTime}`, {
    headers: { Authorization: `Token ${authKey}` },
  });
};

export const getEnteriesByRange = (startDate: any, endDate: any): any => {
  const start = formatDateShort(startDate);
  const end = formatDateShort(endDate);
  const authKey = getAuthKey();
  return axios.get(
    `${process.env.REACT_APP_API_URL}entry-range/${start}/${end}`,
    { headers: { Authorization: `Token ${authKey}` } }
  );
};
