import { Box, Center, Divider, Heading, Text } from "@chakra-ui/react";
import DatePicker from "react-datepicker";

interface HeaderProps {
  filterType: String;
  setFilterType: Function;
  startDate: any;
  endDate: any;
  onDateChange: any;
}

const Header = ({
  filterType,
  setFilterType,
  startDate,
  endDate,
  onDateChange,
}: HeaderProps) => {
  return (
    <Box my={12}>
      <Box
        display={["block", "block", "flex"]}
        alignItems="center"
        justifyContent="space-between"
        mb={8}
      >
        <Heading as="h2" size="xl" mb={[4, 4, 0]}>
          My Progress
        </Heading>
        <Box display={"flex"} alignItems={"center"}>
          <Text
            as="b"
            color={filterType === "week" ? "brand.300" : "neutral.500"}
            cursor={"pointer"}
            onClick={() => setFilterType("week")}
          >
            This Week
          </Text>
          <Center height={"25px"} width={"25px"}>
            <Divider orientation="vertical" size={"8"} />
          </Center>
          <Text
            as="b"
            color={filterType === "month" ? "brand.300" : "neutral.500"}
            cursor={"pointer"}
            onClick={() => setFilterType("month")}
          >
            This Month
          </Text>
          <Center height={"25px"} width={"25px"}>
            <Divider orientation="vertical" size={"8"} />
          </Center>
          <DatePicker
            selected={startDate}
            onChange={onDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
          />
        </Box>
      </Box>
      <hr style={{ border: "1px solid" }} />
    </Box>
  );
};

export default Header;
