import {
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { register } from "../../scripts/api/user";
import { CONTAINER_WIDTH } from "../../constants";
import { trackEvent } from "../../analytics";

interface RegisterValues {
  email: string;
  password: string;
  confirmPassword: string;
  termsAndCondition: Boolean;
}

const Register = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [inRequest, setInRequest] = useState(false);
  const [registerError, setRegisterError] = useState("");

  const registerHandler = async ({
    email,
    password,
    confirmPassword,
    termsAndCondition,
  }: RegisterValues): Promise<void> => {
    setInRequest(true);
    const { data } = await register({
      email: email,
      password: password,
      passwordConfirm: confirmPassword,
    });

    setInRequest(false);
    if (data.success) {
      localStorage.setItem("WORDCOUNTER_ACCESS_TOKEN", data.values);
      trackEvent("signup");
      navigate("/dashboard", { replace: true });
    } else {
      setRegisterError(data.values);
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .required("Email is required")
      .email("Please Enter Valid Email"),
    password: Yup.string().required("Password is required").min(8),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
    termsAndCondition: Yup.bool().oneOf([true], "Please check the aggrement."),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      termsAndCondition: false,
    },
    onSubmit: (values: RegisterValues) => {
      registerHandler({
        email: values.email,
        password: values.password,
        confirmPassword: values.confirmPassword,
        termsAndCondition: values.termsAndCondition,
      });
    },
    validationSchema: validationSchema,
  });
  return (
    <Container maxW={CONTAINER_WIDTH}>
      <div
        style={{
          backgroundImage: "url('/assets/images/auth_bg.png')",
          width: "100%",
          height: "78vh",
          backgroundSize: "50%",
          backgroundPosition: "100% 50%",
          backgroundRepeat: "no-repeat",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {/* Register Box */}
        <Box
          alignItems={"flex-start"}
          p={"48px 32px"}
          gap={"40px"}
          width={"453px"}
          boxShadow={"1px 3px 15px rgba(0, 0, 0, 0.08)"}
          borderRadius="12px"
          bg={"#fff"}
        >
          {/* Form */}
          <form onSubmit={formik.handleSubmit}>
            {/* Nav */}
            <Flex mb={8}>
              <Link
                to="/login"
                style={{
                  marginRight: "10px",
                }}
              >
                <Text as="b">Login</Text>
              </Link>
              <Center height="25px">
                <Divider orientation="vertical" />
              </Center>
              <Link
                to="/register"
                style={{
                  marginLeft: "10px",
                }}
              >
                <Text as="b" color="brand.300">
                  New Account
                </Text>
              </Link>
            </Flex>

            <Box>
              {registerError.length > 0 && (
                <Text color="accents.300">{registerError}</Text>
              )}
            </Box>

            {/* Email Field */}
            <FormControl>
              <FormLabel size={"sm"} color={"neutral.700"} fontWeight="bold">
                Email
              </FormLabel>
              <Input
                type="text"
                name="email"
                id="email"
                placeholder="Enter your email"
                border={"1px solid"}
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.email && formik.errors.email ? (
                <Text color="accents.300" fontSize={"xs"}>
                  {formik.errors.email}
                </Text>
              ) : null}
            </FormControl>

            {/* Password Field */}
            <FormControl>
              <FormLabel
                mt={2}
                size={"sm"}
                color={"neutral.700"}
                fontWeight="bold"
              >
                Password
              </FormLabel>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  name="password"
                  id="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter a Password"
                  border={"1px solid"}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="md"
                    onClick={() => setShowPassword((prev) => !prev)}
                    bg={"transparent"}
                    variant="unstyled"
                  >
                    <Center>
                      {" "}
                      {showPassword ? <BsEyeSlash /> : <BsEye />}
                    </Center>
                  </Button>
                </InputRightElement>
              </InputGroup>
              {formik.touched.password && formik.errors.password ? (
                <Text color="accents.300" fontSize={"xs"}>
                  {formik.errors.password}
                </Text>
              ) : null}
            </FormControl>

            {/* Confirm Password Field */}
            <FormControl>
              <FormLabel
                mt={2}
                size={"sm"}
                color={"neutral.700"}
                fontWeight="bold"
              >
                Confirm Password
              </FormLabel>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  name="confirmPassword"
                  id="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Re-Enter Password"
                  border={"1px solid"}
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="md"
                    onClick={() => setShowConfirmPassword((prev) => !prev)}
                    bg={"transparent"}
                    variant="unstyled"
                  >
                    <Center>
                      {showConfirmPassword ? <BsEyeSlash /> : <BsEye />}
                    </Center>
                  </Button>
                </InputRightElement>
              </InputGroup>
              {formik.touched.confirmPassword &&
              formik.errors.confirmPassword ? (
                <Text color="accents.300" fontSize={"xs"}>
                  {formik.errors.confirmPassword}
                </Text>
              ) : null}
            </FormControl>

            <Flex justifyContent={"space-between"} mt={2}>
              <Link to="/login">
                <Text fontSize="xs" color="brand.300">
                  Already have an Account?
                </Text>
              </Link>
            </Flex>

            <Flex mt={6}>
              <Checkbox
                colorScheme="green"
                iconSize={"10px"}
                value={formik.values.password}
                onChange={formik.handleChange}
                id="termsAndCondition"
                name="termsAndCondition"
              />
              <Text fontSize="sm" ml={2}>
                You agree to our <a href="#">Terms and Conditions</a>
              </Text>
            </Flex>
            {formik.touched.termsAndCondition &&
            formik.errors.termsAndCondition ? (
              <Text color="accents.300" fontSize={"xs"}>
                {formik.errors.termsAndCondition}
              </Text>
            ) : null}

            <Button
              background={"brand.300"}
              color="white"
              _hover={{ bg: "brand.400" }}
              mt={6}
              w={"120px"}
              borderRadius={"100px"}
              type="submit"
              isLoading={inRequest}
            >
              Sign Up
            </Button>
          </form>
        </Box>
      </div>
    </Container>
  );
};
export default Register;
