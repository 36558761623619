import { Box } from "@chakra-ui/react";
import { Heading } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import { SimpleGrid } from "@chakra-ui/react";
import { TimeIcon, CalendarIcon, EditIcon } from "@chakra-ui/icons";

const Flow = () => {
  return (
    <Box mb={6} py={6} textAlign="center" px={1}>
      <SimpleGrid columns={[1, null, 3]} spacing={10}>
        <Box
          px={4}
          py={6}
          pb={12}
          borderRadius="lg"
          style={{ boxShadow: "0px 7px 40px rgba(0, 0, 0, 0.1)" }}
        >
          <TimeIcon w={6} h={6} color="brand.300" />
          <Heading pt={5} pb={4} color="brand.300" as="h2" size="lg">
            Set Your Goals
          </Heading>
          <Text pt={4}>
            Set your goals to help you stay consistent in your writing habit.
          </Text>
        </Box>
        <Box
          px={4}
          py={6}
          pb={12}
          borderRadius="lg"
          style={{ boxShadow: "0px 7px 40px rgba(0, 0, 0, 0.1)" }}
        >
          <CalendarIcon w={6} h={6} color="brand.300" />
          <Heading pt={5} pb={4} color="brand.300" as="h2" size="lg">
            Track Your Writing
          </Heading>
          <Text pt={4}>
            Track every word to help you stay on track and write more often.
          </Text>
        </Box>
        <Box
          px={4}
          py={6}
          pb={12}
          borderRadius="lg"
          style={{ boxShadow: "0px 7px 40px rgba(0, 0, 0, 0.1)" }}
        >
          <EditIcon w={6} h={6} color="brand.300" />
          <Heading pt={5} pb={4} color="brand.300" as="h2" size="lg">
            Write More
          </Heading>
          <Text pt={4}>
            Stay motivated and become a better writer by watching your count
            increase.
          </Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default Flow;
