import axios from 'axios';
import { getAuthKey } from '../api';


interface LoginParams {
  email: string;
  password: string
}

export const register = (user: any): any => {
  const params = {
    email: user.email,
    password: user.password,
    'password-confirm': user.passwordConfirm
  }

  return axios.post(`${process.env.REACT_APP_API_URL}user`, params)
}


export const login = (data: LoginParams): any => {
  return axios.post(`${process.env.REACT_APP_API_URL}user/login`, data)
}

export const checkAuth = (): any => {
  const authKey = getAuthKey();
  return axios.get(`${process.env.REACT_APP_API_URL}user`, {headers: { 'Authorization': `Token ${authKey}` }})
}
