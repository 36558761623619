import React, { createContext, useState } from "react";
import mixpanel from "mixpanel-browser";
import jwt_decode from "jwt-decode";

const mpUserSetup = (userToken: string | null): void => {
  if (userToken) {
    try {
      const deToken = jwt_decode(userToken) as any;
      mixpanel.identify(deToken?._user_id);
    } catch (e: any) {
      console.log("FAILED TOKEN"); // TODO FIX
      mixpanel.identify("None");
    }
  } else {
    mixpanel.identify("None");
  }
};

export const userAuthContext = createContext<{
  authError: string | null;
  setAuthError: (newValue: any) => void;
}>({
  authError: null,
  setAuthError: () => undefined,
});

export const UserAuthProvider = (props: any) => {
  // this state will be shared with all components
  const [authError, setAuthError] = useState(null);

  return (
    // this is the provider providing state
    <userAuthContext.Provider value={{ authError, setAuthError }}>
      {props.children}
    </userAuthContext.Provider>
  );
};

export const userTokenContext = createContext<{
  userToken: string | null;
  setUserToken: (newValue: any) => void;
}>({
  userToken: null,
  setUserToken: () => undefined,
});

export const UserTokenProvider = (props: any) => {
  const [userToken, setUserToken] = useState(null);

  mpUserSetup(userToken);

  return (
    // this is the provider providing state
    <userTokenContext.Provider value={{ userToken, setUserToken }}>
      {props.children}
    </userTokenContext.Provider>
  );
};
