import React, { useState } from "react";
import { Box } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { FormLabel } from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "react-query";
import DatePicker from "react-datepicker";
import { CalendarIcon } from "@chakra-ui/icons";
import { onSave } from "../../../scripts/api/goals";
import toast from "react-hot-toast";
import { trackEvent } from "../../../analytics";

interface AddGoalsProps {
  isOpen: boolean;
  onOpen: any;
  onClose: any;
}

const AddGoal = ({ isOpen, onOpen, onClose }: AddGoalsProps) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [wordCount, setWordCount] = useState(0);
  const [goalNote, setGoalNote] = useState("");

  const queryClient = useQueryClient();

  const mutation = useMutation(onSave, {
    onSuccess: (res: any) => {
      toast.success("Goal Saved!");
      trackEvent("add_goal");
      queryClient.setQueryData(["getGoals"], (data: any) => {
        return { data: [...data.data, res.data] };
      });

      setWordCount(0);
      setGoalNote("");
      onClose();
    },
    onError: (err: any) => {
      toast.error("There was an error.");
    },
  });

  return (
    <div>
      {/* Add goal */}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Goal</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box display="flex" className="AddGoal">
              <Box width="50%" pr={2}>
                <FormLabel>Start Date:</FormLabel>
                <Box position={"relative"}>
                  <DatePicker
                    selected={startDate}
                    onChange={(date: Date) => setStartDate(date)}
                  />
                  <Box
                    position={"absolute"}
                    style={{ right: "10px", top: "5px" }}
                  >
                    <CalendarIcon />
                  </Box>
                </Box>
              </Box>
              <Box width="50%">
                <FormLabel>End Date:</FormLabel>
                <Box position={"relative"}>
                  <DatePicker
                    selected={endDate}
                    onChange={(date: Date) => setEndDate(date)}
                  />
                  <Box
                    position={"absolute"}
                    style={{ right: "10px", top: "5px" }}
                  >
                    <CalendarIcon />
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box pt={3}>
              <FormLabel>Goal Word Count:</FormLabel>
              <Input
                placeholder="Word Count"
                onChange={(e: any) => {
                  setWordCount(e.target.value);
                }}
                value={wordCount}
              />
            </Box>
            <Box pt={3}>
              <FormLabel>Goal Note:</FormLabel>
              <Input
                placeholder="Note"
                onChange={(e: any) => {
                  setGoalNote(e.target.value);
                }}
                value={goalNote}
              />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="solid"
              colorScheme={"green"}
              onClick={() =>
                mutation.mutate({
                  goal_count: wordCount,
                  goal_note: goalNote,
                  goal_start_date: startDate,
                  goal_date: endDate,
                })
              }
            >
              Add Goal
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AddGoal;
