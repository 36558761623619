import axios from 'axios';
import {getAuthKey, formatDate} from '../api';

export const getGoals = (): any => {
  const authKey = getAuthKey();
  return axios.get(`${process.env.REACT_APP_API_URL}goal`, {headers: { 'Authorization': `Token ${authKey}` }});
}


export const onSave = (goal: Goal): any => {
  // TODO move this
  const localISOTimeStart = formatDate(goal.goal_start_date);
  const localISOTimeEnd = formatDate(goal.goal_date);
  const authKey = getAuthKey();

  return axios.post(
    `${process.env.REACT_APP_API_URL}goal`,
    {...goal, 
      goal_start_date: localISOTimeStart,
      goal_date: localISOTimeEnd},
    {headers: { 'Authorization': `Token ${authKey}` }},);
}

export const onEdit = (goal: Goal): any => {
  const localISOTimeStart = formatDate(new Date(goal.goal_start_date));
  const localISOTimeEnd = formatDate(new Date(goal.goal_date));
  const authKey = getAuthKey();

  const {current_count, ...formattedGoal} = goal

  return axios.post(
    `${process.env.REACT_APP_API_URL}goal-edit/${goal.goal_id}`,
    {...formattedGoal, 
      goal_start_date: localISOTimeStart,
      goal_date: localISOTimeEnd},
    {headers: { 'Authorization': `Token ${authKey}` }},);
}

export const onDelete = (id: any): any => {
  const authKey = getAuthKey();

  return axios.delete(
    `${process.env.REACT_APP_API_URL}goal-delete/${id}`,
    {headers: { 'Authorization': `Token ${authKey}` }}
  )
}